import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import ApplicationLayout from "layouts/application";
import ApplicationGroupLayout from "layouts/application-group";
import Client from "layouts/application-group/pages/client";
import GroupMain from "layouts/application-group/pages/main";
import GroupResult from "layouts/application-group/pages/result";
import GroupScoring from "layouts/application-group/pages/scoring";
import GroupLegalCase from "layouts/application-group/pages/legal-case";
import GroupOCR from "layouts/application-group/pages/ocr";
import MainLayout from "layouts/main";
import Scoring from "pages/scoring";
import Home from "pages/home";
import HomeSingleLoans from "pages/home-single-loans";
import HomeGroupLoans from "pages/home-group-loans";
import Users from "pages/users";
import Login from "pages/login";
import OCR from "pages/ocr";
import Result from "pages/result";
import Settings from "pages/settings";
import ActionHistory from "pages/action-history";
import Dashboard from "pages/dashboard";

const AppRoutes: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  React.useLayoutEffect(() => {
    request.get("validate_token").then((res) => {
      dispatch.user.INIT(res.data);
    });
  }, [dispatch]);

  return (
    <Suspense fallback="Loading">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route element={<Home />}>
            <Route path="/" element={<HomeSingleLoans />} />
            <Route path="/group-loans" element={<HomeGroupLoans />} />
          </Route>
          <Route path="/application-group" element={<ApplicationGroupLayout />}>
            <Route path="/application-group/:id" element={<GroupMain />} />
            <Route path="/application-group/:id/result" element={<GroupResult />} />
            <Route path="/application-group/:id/client" element={<Client />}>
              <Route
                path="/application-group/:id/client/:clientId"
                element={<GroupLegalCase />}
              />
              <Route
                path="/application-group/:id/client/:clientId/ocr"
                element={<GroupOCR />}
              />
              <Route
                path="/application-group/:id/client/:clientId/scoring"
                element={<GroupScoring />}
              />
            </Route>
          </Route>
          <Route path="/application" element={<ApplicationLayout />}>
            <Route path="/application/:id/ocr" element={<OCR />} />
            <Route path="/application/:id/scoring" element={<Scoring />} />
            <Route path="/application/:id/result" element={<Result />} />
          </Route>
          <Route
            path="/settings"
            element={user.role !== "expert" ? <Settings /> : <></>}
          />
          <Route
            path="/action-history"
            element={user.role !== "expert" ? <ActionHistory /> : <></>}
          />
          <Route path="/users" element={user.role !== "expert" ? <Users /> : <></>} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
