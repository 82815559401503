import Button from "components/button";
import Input from "layouts/application-group/components/input";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import request from "request";
import transcript from "utils/transcript";
import styles from "./create-modal.module.scss";

const Modal = ({ onClose }: { onClose: () => void }) => {
  const [branch, setBranch] = React.useState("");
  const [loan_purpose, set_loan_purpose] = React.useState("");
  const [remaining_payments, set_remaining_payments] = React.useState("");
  const [active_loans, set_active_loans] = React.useState("");
  const [city, set_city] = React.useState("");
  const [sum, setSum] = React.useState("");
  const [interestRate, setInterestRate] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [clientCount, setClientCount] = React.useState("");

  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name:
      | "clientCount"
      | "active_loans"
      | "remaining_payments"
      | "city"
      | "sum"
      | "interestRate"
      | "duration"
      | "branch"
      | "loan_purpose";
    value: string;
  } | null>(null);

  const navigate = useNavigate();

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (branch === "") {
      return setValidError({
        name: "branch",
        value: "Branch is required",
      });
    } else if (loan_purpose === "") {
      return setValidError({
        name: "loan_purpose",
        value: "Loan purpose is required",
      });
    } else if (active_loans === "") {
      return setValidError({
        name: "active_loans",
        value: "Actove loans is required",
      });
    } else if (remaining_payments === "") {
      return setValidError({
        name: "remaining_payments",
        value: "Remaining payments is required",
      });
    } else if (city === "") {
      return setValidError({
        name: "city",
        value: "Client residence is required",
      });
    } else if (sum.trim().length === 0) {
      return setValidError({
        name: "sum",
        value: "Amount is required",
      });
    } else if (interestRate.trim().length === 0) {
      return setValidError({
        name: "interestRate",
        value: "Interest rate is required",
      });
    } else if (duration.trim().length === 0) {
      return setValidError({
        name: "duration",
        value: "Duration is required",
      });
    } else if (clientCount.trim().length === 0) {
      return setValidError({
        name: "clientCount",
        value: "Client count is required",
      });
    } else setValidError(null);
    setPending(true);
    let groupId = "";
    request
      .post("/applications/group", {
        loaners_amount: Number(clientCount),
      })
      .then((res) => res.data)
      .then(async (res) => {
        const applicationsIds = res.applications_ids;
        groupId = res.group_id;

        for (const applicationsId of applicationsIds)
          await request.put(`/applications/group/${groupId}/${applicationsId}`, {
            amount: sum,
            interest_rate: interestRate,
            duration,
            collected_data: {
              active_loans: Number(active_loans),
              branch: transcript.branch.getKeyFromValue(branch),
              remaining_payments: Number(remaining_payments),
            },
            stop_factors: {
              loan_purpose: loan_purpose === "Yes",
              group_loan: true,
            },
            scoring_data: {
              city: transcript.city.getKeyFromValue(city),
              gender: "female",
            },
          });
        navigate("/application-group/" + groupId + "/client/" + applicationsIds[0]);
      })
      .catch((e) => toast.error(e.response.message))
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>New group application</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Branch"
              id="branch"
              value={branch}
              type="select"
              hasFilterText
              options={[
                "Bayader",
                "Zarqa",
                "Middle East",
                "Irbid",
                "Swaileh",
                "Zarqa Jadeedeh",
                "Rsaifeh",
                "Rsaifeh (Jabal Al Shamali)",
                "Hashmi",
                "Baqaa",
                "Marka",
                "Jabal Al Hussien",
                "Aqaba",
                "Irbid Aydoun",
                "Madaba",
                "Al Karak",
                "Ajloun",
                "Marj Al Hamam",
                "Abu Nseir",
                "Hai Nazal",
                "Al Salt",
                "Al Hurria",
                "Jabal Al Naser",
                "Tabarbour",
                "Jarash",
                "Mota Office",
                "Mafraq",
                "Dir Allah",
                "Head Office",
              ]}
              onSelect={(option) => setBranch(option)}
            />
            {validError?.name === "branch" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Prohibited loan purpose"
              id="loan_purpose"
              value={loan_purpose}
              type="select"
              options={["Yes", "No"]}
              infobox={
                <>
                  • Facilities required for the purposes of manufacturing or selling
                  weapons or related services.
                  <br />
                  <br />• Facilities required for gambling clubs or any purposes or
                  activities related to them.
                  <br />
                  <br />• Facilities required for the manufacture of cigarettes, tobacco
                  products and alcoholic beverages.
                  <br />
                  <br />• Facilities required for the production of prohibited medical
                  drugs.
                  <br />
                  <br />• Facilities required to finance political offices.
                </>
              }
              onSelect={(option) => set_loan_purpose(option)}
            />
            {validError?.name === "loan_purpose" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Active loans"
              value={active_loans}
              type="integer"
              onInput={(value) => set_active_loans(value)}
              step={1}
            />
            {validError?.name === "active_loans" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Remaining payments"
              value={remaining_payments}
              type="integer"
              onInput={(value) => set_remaining_payments(value)}
              step={1}
            />
            {validError?.name === "remaining_payments" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Client residence"
              value={city}
              type="select"
              onSelect={(option) => set_city(option)}
              step={1}
              options={[
                "Al Balqa Governorate",
                "Capital Governorate",
                "Zarqa Governorate",
                "Irbid Governorate",
                "Aqaba Governorate",
                "Ajloun Governorate",
                "Madaba Governorate",
                "Karak Governorate",
                "Jerash Governorate",
                "Pillow",
                "Ramtha",
                "Mafraq Governorate",
              ].sort()}
            />
            {validError?.name === "active_loans" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Amount"
              type="integer"
              value={sum}
              onInput={(value) => setSum(value)}
              step={100}
              min={100}
              max={12000}
              unit="JOD"
            />
            {validError?.name === "sum" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Interest rate (annual)"
              type="float"
              value={interestRate}
              onInput={(value) => setInterestRate(value)}
              step={1}
              min={12}
              max={37.6}
              unit="%"
            />
            {validError?.name === "interestRate" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Duration"
              type="integer"
              value={duration}
              onInput={(value) => setDuration(value)}
              step={1}
              min={8}
              max={40}
              unit="mon."
            />
            {validError?.name === "duration" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Number of clients"
              type="integer"
              value={clientCount}
              placeholder="max: 5 person"
              onInput={(value) => setClientCount(value)}
              step={1}
              min={2}
              max={5}
            />
            {validError?.name === "clientCount" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
