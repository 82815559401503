import React from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import * as math from "mathjs";
import { Link } from "react-router-dom";

import Input from "components/input";
import Button from "components/button";
import ButtonLoader from "components/button-loader";
import Icon from "icons";
import prettierNumber from "utils/prettier-number";
import request from "request";
import modalStyles from "./components/create-modal.module.scss";
import Modal from "./components/create-modal";
import styles from "./home-group-loans.module.scss";
import home from "./home";

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

const HomeGroupLoans = () => {
  const [from, setFrom] = React.useState<Date>(firstDayOfYear);
  const [to, setTo] = React.useState<Date>(lastDayOfYear);
  const [page, setPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(1);
  const [newApplicationModal, setNewApplicationModal] = React.useState(false);
  const [pending, setPending] = React.useState(true);

  const [data, setData] = React.useState<home.result[]>([]);

  const handleFilter = async () => {
    const fd = from.toLocaleDateString("en-GB").split("/").reverse().join("-");
    const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
    setPending(true);
    document.querySelector("#applicationsHistory")?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    request
      .get(`/applications/group/history?fd=${fd}&td=${td}&page=${page}`)
      .then((res) => res.data)
      .then((d) => {
        setData(d.records);
        setRowCount(d.total_count);
      })
      .finally(() => setPending(false));
  };

  const handleClear = () => {
    setFrom(firstDayOfYear);
    setTo(lastDayOfYear);
    handleFilter();
  };

  React.useEffect(() => {
    const handleLoadRows = async () => {
      const fd = from.toLocaleDateString("en-GB").split("/").reverse().join("-");
      const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
      setPending(true);
      document.querySelector("#applicationsHistory")?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      request
        .get(`/applications/group/history?fd=${fd}&td=${td}&page=${page}`)
        .then((res) => res.data)
        .then((d) => {
          setData(d.records);
          setRowCount(d.total_count);
        })
        .finally(() => setPending(false));
    };
    handleLoadRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div className={styles.tools}>
        <div className={styles.filterBox}>
          <div className={styles.input}>
            <Input title="From" value={from} type="date" onSetDate={(d) => setFrom(d)} />
          </div>
          <div className={styles.input}>
            <Input title="To" value={to} type="date" onSetDate={(d) => setTo(d)} />
          </div>
          <div className={styles.buttonGroup}>
            <Button pending={pending} onClick={handleFilter}>
              Filter
            </Button>
            <Button pending={pending} color="red" onClick={handleClear}>
              Clear
            </Button>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          {/* <Button
              pending={exportExcelPending || pending}
              color="orange"
              onClick={handleExportExcel}
            >
              Export .XLSX file
            </Button> */}
          <Button color="green" onClick={() => setNewApplicationModal(true)}>
            New group application
          </Button>
        </div>
      </div>
      {pending && (
        <div className={styles.tableLoading}>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
          <div className={styles.tableLoadingRow}>
            <ButtonLoader />
          </div>
        </div>
      )}
      {!pending && data.length !== 0 && (
        <div className={styles.table}>
          {data.map((item) => (
            <Link
              to={`/application-group/${item.id}`}
              className={styles.tableRowWrap}
              key={item.id}
            >
              <div className={styles.tableRow}>
                <div className={styles.item}>
                  <span>Group ID</span> {item.id}
                </div>
                <div className={styles.item}>
                  <span>Expert</span>
                  {item?.applications[0].created_by.email}
                </div>
                <div className={styles.item}>
                  <span>Create date</span>
                  {new Date(item.created_at).toLocaleString()}
                </div>
                <div className={styles.item}>
                  <span>Amount</span>
                  {prettierNumber(item.applications[0].amount)} JOD
                </div>
                <div className={styles.item}>
                  <span>Duration</span>
                  {item.applications[0].duration} mon.
                </div>
                <div className={styles.item}>
                  <span>Interest rate</span>
                  {(item.applications[0].interest_rate * 100).toFixed()}%
                </div>
                <div
                  className={classNames(
                    styles.item,
                    (item.expert_decision === "approved" ||
                      item.is_approved === "approved") &&
                      styles.green,
                    (item.expert_decision === "rejected" ||
                      item.is_approved === "rejected") &&
                      styles.red,
                    item.is_approved === "review" && styles.yellow
                  )}
                >
                  <span>Result</span>
                  {item.expert_decision === "approved"
                    ? "Approved"
                    : item.expert_decision === "rejected"
                    ? "Rejected"
                    : item.is_approved === null
                    ? "Draft"
                    : item.is_approved === "approved"
                    ? "Approved"
                    : item.is_approved === "rejected"
                    ? "Rejected"
                    : "Review"}
                </div>
                <div className={styles.item}>
                  <span>Probability of approval</span>
                  {item.applications[0].scoring_log?.prediction
                    ? `${item.applications[0].scoring_log?.prediction.toFixed(2)}%`
                    : "–"}
                </div>
                <div className={styles.item}>
                  <span>Threshold</span>
                  {item.applications[0].scoring_log?.threshold
                    ? `${item.applications[0].scoring_log?.threshold * 100}%`
                    : "–"}
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
      {!pending && data.length === 0 && (
        <div className={styles.tableIsEmptyInfo}>No applications</div>
      )}
      {rowCount > 15 && (
        <div className={styles.pagination}>
          <button
            className={classNames(styles.arrow, page <= 1 && styles.disabled)}
            onClick={() => setPage(math.max(page - 1, 1))}
          >
            <Icon name="arrowLeft" />
          </button>
          <div className={styles.label}>
            {page} / {math.ceil(rowCount / 15)}
          </div>
          <button
            className={classNames(styles.arrow, page * 15 >= rowCount && styles.disabled)}
            onClick={() => setPage(math.min(page + 1, math.ceil(rowCount / 15)))}
          >
            <Icon name="arrowRight" />
          </button>
        </div>
      )}
      <CSSTransition
        in={!!newApplicationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal onClose={() => setNewApplicationModal(false)} />
      </CSSTransition>
    </>
  );
};

export default HomeGroupLoans;
