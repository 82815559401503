import React from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import Navbar from "components/navbar";
import Loading from "components/loading";
import styles from "./application.module.scss";

const ApplicationLayout = () => {
  const { id } = useParams();

  const [ocrPending, setOcrPending] = React.useState(false);

  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);
  const [new_financial_legal_case, set_new_financial_legal_case] = React.useState({
    key: "",
    value: "",
  });
  const [bad_credit_reputation_case, set_bad_credit_reputation_case] = React.useState({
    key: "",
    value: "",
  });
  const [hasLegalCase, setHasLegalCase] = React.useState(false);
  const [legalCaseDate, setLegalCaseDate] = React.useState("");

  const handleRunOCR = async (fileCRIF: File | null) => {
    if (!fileCRIF) return toast.error("Please upload CRIF file");
    setOcrPending(true);
    const fd = new FormData();
    fd.append("application_id", id!);
    fd.append("crif", fileCRIF);

    if (hasLegalCase)
      request
        .post(`/applications/${id}/legal_case`, {
          financial_legal_case: new_financial_legal_case.key === "yes",
          bad_credit_reputation_case: bad_credit_reputation_case.key === "yes",
          legal_case_date: legalCaseDate.split("/").reverse().join("-"),
        })
        .then(() => {
          dispatch.application.FETCH_APPLICATION_BY_ID_NO_PENDING(id);
          request({
            url: "/applications/ocr",
            method: "POST",
            data: fd,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((res) => res.data)
            .then(() => dispatch.application.FETCH_APPLICATION_BY_ID_NO_PENDING(id))
            .catch((res: AxiosError) => {
              if (res.response?.status === 422) toast.error("Please upload CRIF file");
            })
            .finally(() => setOcrPending(false));
        })
        .catch(() => {
          toast.error("Invalid Legal Case Date");
        })
        .finally(() => {
          setOcrPending(false);
        });
    request({
      url: "/applications/ocr",
      method: "POST",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => res.data)
      .then(() => dispatch.application.FETCH_APPLICATION_BY_ID_NO_PENDING(id))
      .catch((res: AxiosError) => {
        if (res.response?.status === 422) toast.error("Please upload CRIF file");
      })
      .finally(() => setOcrPending(false));
  };

  React.useLayoutEffect(() => {
    dispatch.application.FETCH_APPLICATION_BY_ID(id);
  }, [dispatch.application, id]);

  return (
    <div className={styles.applicationLayout} id="application-layout">
      {contextApplication.pending && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      {!contextApplication.pending && (
        <div className={styles.container}>
          <div className={styles.navbar}>
            <Navbar
              ocrPending={ocrPending}
              resultBlocked={contextApplication.data?.is_approved === null}
            />
          </div>
          <div className={styles.content}>
            <Outlet
              context={{
                new_financial_legal_case,
                set_new_financial_legal_case,
                bad_credit_reputation_case,
                set_bad_credit_reputation_case,
                hasLegalCase,
                setHasLegalCase,
                legalCaseDate,
                setLegalCaseDate,
                ocrPending,
                handleRunOCR,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

type ContextType = {
  new_financial_legal_case: {
    key: string;
    value: string;
  };
  set_new_financial_legal_case: React.Dispatch<
    React.SetStateAction<{
      key: string;
      value: string;
    }>
  >;
  bad_credit_reputation_case: {
    key: string;
    value: string;
  };
  set_bad_credit_reputation_case: React.Dispatch<
    React.SetStateAction<{
      key: string;
      value: string;
    }>
  >;
  hasLegalCase: boolean;
  setHasLegalCase: React.Dispatch<React.SetStateAction<boolean>>;
  legalCaseDate: string;
  setLegalCaseDate: React.Dispatch<React.SetStateAction<string>>;
  ocrPending: boolean;
  handleRunOCR: (fileCRIF: File | null) => void;
};

export const useOCR = () => {
  return useOutletContext<ContextType>();
};

export default ApplicationLayout;
