import React from "react";
import { Router } from "react-router-dom";
import { history } from "../request";

type Props = {
  children: React.ReactNode;
};

const CustomRouter = ({ children }: Props) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), []);

  return (
    <Router location={state.location} navigator={history} navigationType={state.action}>
      {children}
    </Router>
  );
};

export default CustomRouter;
