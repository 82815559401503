import React from "react";
import classNames from "classnames";

import { useClientContext } from "layouts/application-group";
import styles from "./client-result-box.module.scss";

const ClientResultBox = () => {
  const { application, stop_factors } = useClientContext();

  return (
    <div className={styles.result_box}>
      <div className={styles.title}>Client {application.id}</div>
      <div className={styles.item}>
        <div className={styles.key}>Legal case</div>
        <div
          className={classNames(
            styles.value,
            application.legal_case_log &&
              (application.legal_case_log?.is_approved === "rejected"
                ? styles.red
                : application.legal_case_log?.is_approved === "review"
                ? styles.yellow
                : styles.green)
          )}
        >
          {!application.legal_case_log?.is_approved
            ? "No decision"
            : application.legal_case_log?.is_approved === "rejected"
            ? "Rejected"
            : application.legal_case_log?.is_approved === "review"
            ? "Review"
            : "Approved"}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.key}>OCR</div>
        <div
          className={classNames(
            styles.value,
            application.ocr_log &&
              (application.ocr_log?.is_approved === "rejected"
                ? styles.red
                : application.ocr_log?.is_approved === "review"
                ? styles.yellow
                : styles.green)
          )}
        >
          {!application.ocr_log?.is_approved
            ? "No decision"
            : application.ocr_log?.is_approved === "rejected"
            ? "Rejected"
            : application.ocr_log?.is_approved === "review"
            ? "Review"
            : "Approved"}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.key}>Manual stop factors</div>
        <div
          className={classNames(
            styles.value,
            stop_factors &&
              (stop_factors.is_approved === "rejected"
                ? styles.red
                : stop_factors.is_approved === "review"
                ? styles.yellow
                : styles.green)
          )}
        >
          {!stop_factors
            ? "No decision"
            : stop_factors.is_approved === "rejected"
            ? "Rejected"
            : stop_factors.is_approved === "review"
            ? "Review"
            : "Approved"}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.key}>Scoring</div>
        <div
          className={classNames(
            styles.value,
            application.scoring_log?.is_approved &&
              (application.scoring_log?.is_approved === "rejected"
                ? styles.red
                : application.scoring_log?.is_approved === "review"
                ? styles.yellow
                : styles.green)
          )}
        >
          {!application.scoring_log?.is_approved
            ? "No decision"
            : `${
                application.scoring_log?.is_approved === "rejected"
                  ? "Rejected"
                  : application.scoring_log?.is_approved === "review"
                  ? "Review"
                  : "Approved"
              } (${application.scoring_log?.prediction?.toFixed(2)}%)`}
        </div>
      </div>
    </div>
  );
};

export default ClientResultBox;
