import { createModel } from "@rematch/core";
import global from "global";
import request from "request";

const initialValue: global.contextApplication = {
  pending: false,
  data: null,
  manual_factors: null,
};

const application = createModel()({
  state: initialValue as global.contextApplication,
  reducers: {
    START_PENDING(state) {
      return { ...state, pending: true };
    },
    STOP_PENDING(state) {
      return { ...state, pending: false };
    },
    SET(state, payload: any) {
      let application: global.application = payload.application ?? payload;
      let manual_factors: global.manual_factors =
        payload.manual_factors ?? null;
      if (JSON.stringify(state.data) === JSON.stringify(application))
        return state;
      else {
        return {
          ...state,
          data: { ...application },
          manual_factors: { ...manual_factors },
        };
      }
    },
    REMOVE() {
      return initialValue;
    },
  },
  effects: (dispatch) => ({
    async FETCH_APPLICATION_BY_ID(id) {
      dispatch.application.START_PENDING();
      const response = await request.get("applications/" + id);
      const data = await response.data;
      dispatch.application.STOP_PENDING();
      dispatch.application.SET(data);
    },
    async FETCH_APPLICATION_BY_ID_NO_PENDING(id) {
      const response = await request.get("applications/" + id);
      const data = await response.data;
      dispatch.application.SET(data);
    },
  }),
});

export default application;
