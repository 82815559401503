import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";

import global from "global";
import transcript from "utils/transcript";
import { useSelector } from "store/hooks";
import styles from "./result.module.scss";
import ResultBox from "components/result-box";
import { stopFactorStatusTranscript } from "utils/prettier-status";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "10px",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "3px",
    marginTop: "10px",
  },
  text: {
    fontSize: "14px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  result: {
    fontSize: "18px",
    fontWeight: 700,
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const Result = () => {
  const contextApplication = useSelector((state) => state.application);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (contextApplication.data?.is_approved === null) navigate("/");
  }, [contextApplication, navigate]);

  return (
    <div className={styles.result}>
      <div className={styles.pdf}>
        <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
          <Pdf contextApplication={contextApplication} />
        </PDFViewer>
      </div>
      <div className={styles.resultBoxGroup}>
        <ResultBox />
      </div>
    </div>
  );
};

const Pdf = ({
  contextApplication,
}: {
  contextApplication: global.contextApplication;
}) => {
  return (
    <Document>
      {contextApplication.data?.scoring_log && (
        <>
          <Page style={pdfStyles.page}>
            <View style={pdfStyles.heading}>
              <Text>Application report</Text>
            </View>
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.text}>
                Date/Time:{" "}
                {new Date(contextApplication.data?.created_at ?? "").toLocaleString()}
              </Text>
              <Text style={pdfStyles.text}>
                Application: {contextApplication.data?.id}
              </Text>
              <Text style={pdfStyles.title}>Credit data</Text>
              <View style={pdfStyles.table}>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Amount</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.amount} JOD</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Duration</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.duration} months</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Interest rate (annual)</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {(Number(contextApplication.data?.interest_rate) * 100).toFixed()}%
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Threshold</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.threshold * 100}%</Text>
                  </View>
                </View>
              </View>
              <Text style={pdfStyles.title}>Client data</Text>
              <View style={pdfStyles.table}>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Gender</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.genderСode.getValueFromKey(
                        contextApplication.data?.scoring_log?.gender
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Family status</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.maritalStatusEng.getValueFromKey(
                        contextApplication.data?.scoring_log?.family_status
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Dependants</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.dependants}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Prior loans</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.prior_loans}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Client residence</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.city.getValueFromKey(
                        contextApplication.data?.scoring_log?.city
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              {contextApplication.data?.scoring_log?.prediction &&
                contextApplication.data?.scoring_log?.threshold && (
                  <View style={pdfStyles.bottom}>
                    <Text style={pdfStyles.result}>
                      Result:{" "}
                      {contextApplication.data?.is_approved === "approved"
                        ? "Approved"
                        : contextApplication.data.is_approved === "rejected"
                        ? "Rejected"
                        : "Review"}{" "}
                      {contextApplication.data?.scoring_log?.prediction}%
                    </Text>
                    <Text style={pdfStyles.result}>
                      Threshold:{" "}
                      {Number(contextApplication.data?.scoring_log?.threshold) * 100}%
                    </Text>
                  </View>
                )}
            </View>
          </Page>
          <Page style={pdfStyles.page}>
            <Text style={pdfStyles.title}>Alternative data</Text>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Phone OS</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.phone_os}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Transport</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.transport}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Residence Type</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.residence_type}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Employment Level</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.employment_level}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Employment Status</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.employment_status}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Work Experience</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.work_experience}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Transport Manufacturing Year</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.transport_manufacturing_year}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Branch</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.branch.getValueFromKey(
                      contextApplication.data?.scoring_log?.branch
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Nationality</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.nationality}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Address</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.address}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Active loans</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.active_loans}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Repeated client?</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.repeated_client}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Remaining payments</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.remaining_payments}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Postpone payments</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.postpone_payments}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Early settlement</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.early_settlement}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Project net monthly income</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.project_net_monthly_income} JOD
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Additional income</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.additional_income} JOD
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Monthly payment for active loans</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      contextApplication.data?.scoring_log
                        ?.monthly_payment_for_active_loans
                    }{" "}
                    JOD
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Monthly payment</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.scoring_log.monthly_payment.toFixed(1)} JOD
                  </Text>
                </View>
              </View>
              {contextApplication.data?.scoring_log?.guarantor_income !== null && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Guarantor income</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.guarantor_income} JOD
                    </Text>
                  </View>
                </View>
              )}
              {contextApplication.data?.scoring_log?.guarantor_total_monthly_payments !==
                null && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Guarantor total monthly payments</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {
                        contextApplication.data?.scoring_log
                          ?.guarantor_total_monthly_payments
                      }{" "}
                      JOD
                    </Text>
                  </View>
                </View>
              )}
              {contextApplication.data?.scoring_log?.guarantor_additional_income !==
                null && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Guarantor additional income</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.guarantor_additional_income}{" "}
                      JOD
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </Page>
          <Page style={pdfStyles.page}>
            <Text style={pdfStyles.title}>Manual stop factors</Text>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>New financial legal case</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {!contextApplication.data.legal_case_log &&
                    contextApplication.data.ocr_log
                      ? "N/A"
                      : contextApplication.data.legal_case_log?.financial_legal_case ===
                        "rejected"
                      ? "Yes"
                      : "No"}{" "}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.data.legal_case_log?.financial_legal_case
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Bad credit reputation case</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {!contextApplication.data.legal_case_log &&
                    contextApplication.data.ocr_log
                      ? "N/A"
                      : contextApplication.data.legal_case_log
                          ?.bad_credit_reputation_case === "rejected"
                      ? "Yes"
                      : "No"}{" "}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.data.legal_case_log?.bad_credit_reputation_case
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Legal case date</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {/* {!contextApplication.data.legal_case_log &&
                    contextApplication.data.ocr_log
                      ? "N/A"
                      : contextApplication.data.legal_case_log?.is_approved === "rejected"
                      ? "Yes"
                      : "No"}{" "} */}
                    {contextApplication.data.legal_case_log?.legal_case_date ?? ""}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  {/* <Text>
                    {stopFactorStatusTranscript(
                      !contextApplication.data.legal_case_log &&
                        contextApplication.data.ocr_log
                        ? "approved"
                        : contextApplication.data.legal_case_log?.is_approved
                    )}
                  </Text> */}
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Group loan</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.group_loan ? "Yes" : "No"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.group_loan
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Prohibited loan purpose</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.loan_purpose ? "Yes" : "No"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.loan_purpose
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Age</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.scoring_log?.age} y/o</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(contextApplication.manual_factors?.age)}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Dual loans</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.dual_loans ? "Yes" : "No"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.dual_loans
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Max days late payments</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.max_days_late_payments}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.max_days_late_payments
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Client is already a guarantor for another active loan?</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.client_status_gurantor
                      ? "Yes"
                      : "No"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.client_status_gurantor
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Family members loans (in the same house)</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.family_members_loans
                      ? "Yes"
                      : "No"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.family_members_loans
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Family members delinquent (in the same house)</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data?.scoring_log?.family_members_delinquent
                      ? "Yes"
                      : "No"}
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.family_members_delinquent
                    )}
                  </Text>
                </View>
              </View>
              {contextApplication.data?.scoring_log?.guarantor_age !== null && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Guarantor age</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.guarantor_age}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.guarantor_age
                      )}
                    </Text>
                  </View>
                </View>
              )}
              {contextApplication.data?.scoring_log?.guarantor_has_active_loan !==
                null && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Guarantor has active AMC loan?</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.guarantor_has_active_loan
                        ? "Yes"
                        : "No"}
                    </Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.guarantor_has_active_loan
                      )}
                    </Text>
                  </View>
                </View>
              )}
              {contextApplication.data?.scoring_log?.guarantor_for_another_loan !==
                null && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Guarantor is already a guarantor for another active loan?</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.guarantor_for_another_loan
                        ? "Yes"
                        : "No"}
                    </Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.guarantor_for_another_loan
                      )}
                    </Text>
                  </View>
                </View>
              )}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Client DBR</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {(contextApplication.data?.scoring_log?.client_dbr * 100).toFixed(1)}%
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.client_dbr
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Guarantor DBR</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {(contextApplication.data?.scoring_log?.guarantor_dbr * 100).toFixed(
                      1
                    )}
                    %
                  </Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.guarantor_dbr
                    )}
                  </Text>
                </View>
              </View>
              {contextApplication.data.scoring_log.legal_case !== null && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Risky legal case</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data.scoring_log.legal_case ? "Yes" : "No"}
                    </Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.legal_case
                      )}
                    </Text>
                  </View>
                </View>
              )}
              {contextApplication.data.scoring_log.legal_case_date !== null && (
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Legal case date</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data.scoring_log.legal_case_date}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.legal_case_date
                      )}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </Page>
        </>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
