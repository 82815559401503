import global from "global";

const prettierStatus = (status?: global.stop_factor): string | null | undefined => {
  switch (status) {
    case "approved":
      return "Approved";
    case "rejected":
      return "Rejected";
    case "review":
      return "Review";
    default:
      return status;
  }
};

export const stopFactorStatusTranscript = (
  status?: global.stop_factor | string
): string | undefined | null => {
  switch (status) {
    case "approved":
    case null:
      return "Pass";
    case "rejected":
      return "Fail";
    case "review":
      return "Review";
    default:
      return status;
  }
};

export default prettierStatus;
