import React from "react";
import classNames from "classnames";
import styles from "./toggle-box.module.scss";

const ToggleBox = ({
  active: propActive,
  onToggle,
}: {
  active?: boolean;
  onToggle: () => void;
}) => {
  const [active, setActive] = React.useState(propActive);
  const [isMouseDown, setMouseDown] = React.useState(false);
  const mouseXPosition = React.useRef<number>();
  const block = React.useRef<boolean>();

  const handleToggle = () => !block.current && onToggle();

  const handleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (!isMouseDown || !mouseXPosition.current) return;
      block.current = true;
      if (e.clientX >= mouseXPosition.current + 9) {
        if (!active) {
          onToggle();
        }
      } else if (e.clientX <= mouseXPosition.current - 9) {
        if (active) {
          onToggle();
        }
      } else {
        block.current = false;
      }
    },
    [active, isMouseDown, onToggle]
  );

  React.useEffect(() => {
    setActive(propActive);
  }, [propActive]);

  React.useEffect(() => {
    const handleMouseUp = () => {
      mouseXPosition.current = undefined;
      document.body.style.cursor = "default";
      setMouseDown(false);
    };
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  return (
    <button
      type="button"
      onClick={handleToggle}
      className={classNames(styles.toggleBox, active && styles.active)}
      onMouseDown={(e) => {
        setMouseDown(true);
        block.current = false;
        document.body.style.cursor = "pointer";
        mouseXPosition.current = e.clientX;
      }}
    />
  );
};

export default ToggleBox;
