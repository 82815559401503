const transcript = {
  productEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Сельскохозяйственный":
          return "agriculture";
        case "Предпринимательский":
          return "business";
        case "Потребительский":
          return "consumer";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "agriculture":
          return "Agriculture";
        case "business":
          return "Business";
        case "consumer":
          return "Consumer";
        default:
          return "";
      }
    },
  },
  city: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "al_balqa_governorate":
          return "Al Balqa Governorate";
        case "capital_governorate":
          return "Capital Governorate";
        case "zarqa_governorate":
          return "Zarqa Governorate";
        case "irbid_governorate":
          return "Irbid Governorate";
        case "aqaba_governorate":
          return "Aqaba Governorate";
        case "ajloun_governorate":
          return "Ajloun Governorate";
        case "madaba_governorate":
          return "Madaba Governorate";
        case "karak_governorate":
          return "Karak Governorate";
        case "jerash_governorate":
          return "Jerash Governorate";
        case "pillow":
          return "Pillow";
        case "ramtha":
          return "Ramtha";
        case "mafraq_governorate":
          return "Mafraq Governorate";
        default:
          return "";
      }
    },
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Al Balqa Governorate":
          return "al_balqa_governorate";
        case "Capital Governorate":
          return "capital_governorate";
        case "Zarqa Governorate":
          return "zarqa_governorate";
        case "Irbid Governorate":
          return "irbid_governorate";
        case "Aqaba Governorate":
          return "aqaba_governorate";
        case "Ajloun Governorate":
          return "ajloun_governorate";
        case "Madaba Governorate":
          return "madaba_governorate";
        case "Karak Governorate":
          return "karak_governorate";
        case "Jerash Governorate":
          return "jerash_governorate";
        case "Pillow":
          return "pillow";
        case "Ramtha":
          return "ramtha";
        case "Mafraq Governorate":
          return "mafraq_governorate";
        default:
          return "";
      }
    },
  },
  clientTypeCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "New":
          return 0;
        case "Repeat":
          return 1;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 0:
        case "0":
          return "New";
        case 1:
        case "1":
          return "Repeat";
        default:
          return "";
      }
    },
  },
  branch: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "bayader":
          return "Bayader";
        case "zarqa":
          return "Zarqa";
        case "middle_east":
          return "Middle East";
        case "irbid":
          return "Irbid";
        case "swaileh":
          return "Swaileh";
        case "zarqa_jadeedeh":
          return "Zarqa Jadeedeh";
        case "rsaifeh":
          return "Rsaifeh";
        case "rsaifeh_(jabal_al_shamali)":
          return "Rsaifeh (Jabal Al Shamali)";
        case "hashmi":
          return "Hashmi";
        case "baqaa":
          return "Baqaa";
        case "marka":
          return "Marka";
        case "jabal_al_hussien":
          return "Jabal Al Hussien";
        case "aqaba":
          return "Aqaba";
        case "irbid-aydoun":
          return "Irbid Aydoun";
        case "madaba":
          return "Madaba";
        case "al_karak":
          return "Al Karak";
        case "ajloun":
          return "Ajloun";
        case "marj_al_hamam":
          return "Marj Al Hamam";
        case "abu_nseir":
          return "Abu Nseir";
        case "hai_nazal":
          return "Hai Nazal";
        case "al_salt":
          return "Al Salt";
        case "al_hurria":
          return "Al Hurria";
        case "jabal_al_naser":
          return "Jabal Al Naser";
        case "tabarbour":
          return "Tabarbour";
        case "jarash":
          return "Jarash";
        case "mota_office":
          return "Mota Office";
        case "mafraq":
          return "Mafraq";
        case "dir_allah":
          return "Dir Allah";
        case "head_office":
          return "Head Office";
        default:
          return "";
      }
    },
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Bayader":
          return "bayader";
        case "Zarqa":
          return "zarqa";
        case "Middle East":
          return "middle_east";
        case "Irbid":
          return "irbid";
        case "Swaileh":
          return "swaileh";
        case "Zarqa Jadeedeh":
          return "zarqa_jadeedeh";
        case "Rsaifeh":
          return "rsaifeh";
        case "Rsaifeh (Jabal Al Shamali)(jabal_al_shamali)":
          return "rsaifeh_";
        case "Hashmi":
          return "hashmi";
        case "Baqaa":
          return "baqaa";
        case "Marka":
          return "marka";
        case "Jabal Al Hussien":
          return "jabal_al_hussien";
        case "Aqaba":
          return "aqaba";
        case "Irbid Aydoun-aydoun":
          return "irbid";
        case "Madaba":
          return "madaba";
        case "Al Karak":
          return "al_karak";
        case "Ajloun":
          return "ajloun";
        case "Marj Al Hamam":
          return "marj_al_hamam";
        case "Abu Nseir":
          return "abu_nseir";
        case "Hai Nazal":
          return "hai_nazal";
        case "Al Salt":
          return "al_salt";
        case "Al Hurria":
          return "al_hurria";
        case "Jabal Al Naser":
          return "jabal_al_naser";
        case "Tabarbour":
          return "tabarbour";
        case "Jarash":
          return "jarash";
        case "Mota Office":
          return "mota_office";
        case "Mafraq":
          return "mafraq";
        case "Dir Allah":
          return "dir_allah";
        case "Head Office":
          return "head_office";
        default:
          return "";
      }
    },
  },
  genderСode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Male":
          return "male";
        case "Female":
          return "female";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string) => {
      switch (text) {
        case "male":
          return "Male";
        case "female":
          return "Female";
        default:
          return "";
      }
    },
  },
  maritalStatusEng: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Divorced":
          return "divorced";
        case "Married":
          return "married";
        case "Single":
          return "single";
        case "Widow / Widower":
          return "widowed";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case "divorced":
          return "Divorced";
        case "married":
          return "Married";
        case "single":
          return "Single";
        case "widowed":
          return "Widow / Widower";
        default:
          return "";
      }
    },
  },
  workExperienceCat: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "From 1 to 3 years":
          return "from1to3";
        case "From 3 years to 5 years":
          return "from3to5";
        case "More than 5":
          return "morethan5";
        case "No experience":
          return "no_exp";
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case "from1to3":
          return "From 1 to 3 years";
        case "from3to5":
          return "From 3 years to 5 years";
        case "morethan5":
          return "More than 5";
        case "no_exp":
          return "No experience";
        default:
          return "";
      }
    },
  },
  isCarСode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Yes":
          return 1;
        case "No":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return "Yes";
        case 0:
        case "0":
          return "No";
        default:
          return "";
      }
    },
  },
  isRealEstateCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Yes":
          return 1;
        case "No":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return "Yes";
        case 0:
        case "0":
          return "No";
        default:
          return "";
      }
    },
  },
  coBorrowerCode: {
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Yes":
          return 1;
        case "No":
          return 0;
        default:
          return "";
      }
    },
    getValueFromKey: (text: string | number) => {
      switch (text) {
        case 1:
        case "1":
          return "Yes";
        case 0:
        case "0":
          return "No";
        default:
          return "";
      }
    },
  },
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "admin":
          return "Admin";
        case "root":
          return "ROOT";
        default:
          return "Credit expert";
      }
    },
  },
};

export default transcript;
