import React from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import Icon from "icons";
import request from "request";
import Button from "components/button";
import { useClientContext } from "layouts/application-group";
import styles from "./result-box.module.scss";
import prettierStatus from "utils/prettier-status";
import Input from "../input";

const ResultBox = () => {
  const { legalCaseList, group, OCRFileList, setGroup } = useClientContext();

  const { clientId } = useParams();

  const [pendingLegalCase, setPendingLegalCase] = React.useState(false);
  const [pendingOCR, setPendingOCR] = React.useState(false);
  const [pendingScoring, setPendingScoring] = React.useState(false);
  const [pendingSelectRecommendation, setPendingSelectRecommnedation] =
    React.useState(false);
  const [decision, setDecision] = React.useState("");
  const [commentary, setCommentary] = React.useState("");

  const OCRButtonIsBlocked = React.useMemo(
    () => !!OCRFileList.find((file) => !file.file),
    [OCRFileList]
  );
  const LegalCaseButtonIsBlocked = React.useMemo(
    () =>
      !!legalCaseList.find(
        (l) =>
          l.hasLegalCase &&
          (l.bad_credit_reputation_case === "" ||
            l.financial_legal_case === "" ||
            l.legal_case_date.length < 10)
      ),
    [legalCaseList]
  );
  const ScoringButtonIsBlocked = React.useMemo(() => {
    return !!group.applications.find(
      (application) =>
        !application.scoring_log ||
        application.scoring_log.age === null ||
        application.scoring_log.gender === null ||
        application.scoring_log.family_status === null ||
        application.scoring_log.dependants === null ||
        application.scoring_log.nationality === null ||
        application.scoring_log.address === null ||
        application.scoring_log.repeated_client === null ||
        application.scoring_log.prior_loans === null ||
        application.scoring_log.remaining_payments === null ||
        application.scoring_log.postpone_payments === null ||
        application.scoring_log.early_settlement === null ||
        application.scoring_log.dual_loans === null ||
        application.scoring_log.max_days_late_payments === null ||
        application.scoring_log.client_status_gurantor === null ||
        application.scoring_log.family_members_loans === null ||
        application.scoring_log.family_members_delinquent === null ||
        application.scoring_log.project_net_monthly_income === null ||
        application.scoring_log.additional_income === null ||
        application.scoring_log.monthly_payment_for_active_loans === null ||
        application.scoring_log.residence_type === null ||
        application.scoring_log.transport === null ||
        application.scoring_log.employment_status === null ||
        application.scoring_log.transport_manufacturing_year === null ||
        application.scoring_log.employment_level === null ||
        application.scoring_log.work_experience === null ||
        application.scoring_log.phone_os === null
    );
  }, [group.applications]);

  const handleCalculateLegalCase = async () => {
    setPendingLegalCase(true);
    try {
      await request.post(`/applications/group/${group.id}/legal_case`, {
        applications: legalCaseList.map((legalcase) => ({
          application_id: legalcase.id,
          financial_legal_case:
            legalcase.financial_legal_case === "" || !legalcase.hasLegalCase
              ? null
              : legalcase.financial_legal_case === "Yes",
          bad_credit_reputation_case:
            legalcase.bad_credit_reputation_case === "" || !legalcase.hasLegalCase
              ? null
              : legalcase.bad_credit_reputation_case === "Yes",
          legal_case_date:
            legalcase.legal_case_date === "" || !legalcase.hasLegalCase
              ? null
              : legalcase.legal_case_date.split("/").reverse().join("-"),
        })),
      });
      request
        .get(`/applications/group/${group.id}`)
        .then((res) => res.data)
        .then((res) => setGroup(res))
        .finally(() => {
          setPendingLegalCase(false);
        });
    } catch {
      setPendingLegalCase(false);
    }
  };
  const handleCalculateOCR = async () => {
    setPendingOCR(true);
    try {
      const fd = new FormData();
      OCRFileList.forEach((OCRFile) => {
        fd.append("files", OCRFile.file!);
        fd.append("application_ids", String(OCRFile.id));
      });
      await request({
        url: `/applications/group/${group.id}/ocr`,
        method: "POST",
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      });
      request
        .get(`/applications/group/${group.id}`)
        .then((res) => res.data)
        .then((res) => setGroup(res));
    } finally {
      setPendingOCR(false);
    }
  };
  const handleCalculateScoring = async () => {
    setPendingScoring(true);
    request
      .post(`/applications/group/${group.id}/scoring`, {
        applications: group.applications.map((a) => ({
          application_id: a.id,
          data: {
            scoring_data: {
              dependants: a.scoring_log?.dependants,
              family_status: a.scoring_log?.family_status,
              gender: a.scoring_log?.gender,
              age: a.scoring_log?.age,
              prior_loans: a.scoring_log?.prior_loans,
              city: a.scoring_log?.city,
            },
            collected_data: {
              active_loans: a.scoring_log?.active_loans,
              branch: a.scoring_log?.branch,
              phone_os: a.scoring_log?.phone_os,
              transport: a.scoring_log?.transport,
              residence_type: a.scoring_log?.residence_type,
              employment_level: a.scoring_log?.employment_level,
              employment_status: a.scoring_log?.employment_status,
              work_experience: a.scoring_log?.work_experience,
              transport_manufacturing_year: a.scoring_log?.transport_manufacturing_year,
              nationality: a.scoring_log?.nationality,
              address: a.scoring_log?.address,
              repeated_client: a.scoring_log?.repeated_client,
              remaining_payments: a.scoring_log?.remaining_payments,
              postpone_payments: a.scoring_log?.postpone_payments,
              early_settlement: a.scoring_log?.early_settlement,
              project_net_monthly_income: a.scoring_log?.project_net_monthly_income,
              additional_income: a.scoring_log?.additional_income,
              monthly_payment_for_active_loans:
                a.scoring_log?.monthly_payment_for_active_loans,
              guarantor_income: a.scoring_log?.guarantor_income,
              guarantor_additional_income: a.scoring_log?.guarantor_additional_income,
              guarantor_total_monthly_payments:
                a.scoring_log?.guarantor_total_monthly_payments,
            },
            stop_factors: {
              group_loan: a.scoring_log?.group_loan,
              loan_purpose: a.scoring_log?.loan_purpose,
              dual_loans: a.scoring_log?.dual_loans,
              max_days_late_payments: a.scoring_log?.max_days_late_payments,
              client_status_gurantor: a.scoring_log?.client_status_gurantor,
              family_members_loans: a.scoring_log?.family_members_loans,
              family_members_delinquent: a.scoring_log?.family_members_delinquent,
              guarantor_age: a.scoring_log?.guarantor_age,
              guarantor_has_active_loan: a.scoring_log?.guarantor_has_active_loan,
              guarantor_for_another_loan: a.scoring_log?.guarantor_for_another_loan,
            },
          },
        })),
      })
      .then(() => {
        request
          .get(`/applications/group/${group.id}`)
          .then((res) => res.data)
          .then((res) => setGroup(res));
      })
      .finally(() => {
        setPendingScoring(false);
      });
  };
  const handleFinalDecision = () => {
    setPendingSelectRecommnedation(true);
    request
      .post(`/applications/group/${group?.id}/review`, {
        decision: decision === "Approve" ? "approved" : "rejected",
        commentary: commentary,
      })
      .then(() =>
        request
          .get(`/applications/group/${group.id}`)
          .then((res) => res.data)
          .then((res) => setGroup(res))
      )
      .finally(() => setPendingSelectRecommnedation(false));
  };

  return (
    <div className={styles.result_list}>
      {!clientId && group?.expert_decision !== null && (
        <div className={classNames(styles.result_box, styles.final_decision)}>
          <div className={styles.title}>Final decision</div>
          <div className={styles.finalResult}>
            <Icon
              size={120}
              name={group?.expert_decision === "approved" ? "tickCircle" : "closeFilled"}
              className={classNames(
                group?.expert_decision === "approved" ? styles.green : styles.red
              )}
            />
            <div
              className={classNames(
                styles.finalResultText,
                group?.expert_decision === "approved" ? styles.green : styles.red
              )}
            >
              {prettierStatus(group?.expert_decision)}
            </div>
            <div className={styles.finalResultDescription}>
              {group?.expert_decision_description}
            </div>
          </div>
        </div>
      )}
      <div className={styles.result_box}>
        <div className={styles.title}>Application status</div>
        {group.is_approved && (
          <div className={styles.finalResult}>
            <Icon
              size={120}
              name={group.is_approved === "approved" ? "tickCircle" : "closeFilled"}
              className={classNames(
                group.is_approved === "approved"
                  ? styles.green
                  : group.is_approved === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            />
            <div
              className={classNames(
                styles.finalResultText,
                group.is_approved === "approved"
                  ? styles.green
                  : group.is_approved === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {prettierStatus(group.is_approved)}
            </div>
          </div>
        )}
        <div className={styles.item}>
          <div className={styles.key}>Legal case</div>
          {group.legal_case_approved ? (
            <div
              className={classNames(
                styles.value,
                group.legal_case_approved === "rejected"
                  ? styles.red
                  : group.legal_case_approved === "review"
                  ? styles.yellow
                  : styles.green
              )}
            >
              {prettierStatus(group.legal_case_approved)}
            </div>
          ) : (
            <Button
              blocked={LegalCaseButtonIsBlocked}
              color={LegalCaseButtonIsBlocked ? "grey" : "blue"}
              pending={pendingLegalCase}
              onClick={handleCalculateLegalCase}
            >
              Calculate
            </Button>
          )}
        </div>
        <div className={styles.item}>
          <div className={styles.key}>OCR</div>
          {group.ocr_approved ? (
            <div
              className={classNames(
                styles.value,
                group.ocr_approved === "rejected"
                  ? styles.red
                  : group.ocr_approved === "review"
                  ? styles.yellow
                  : styles.green
              )}
            >
              {prettierStatus(group.ocr_approved)}
            </div>
          ) : (
            <Button
              blocked={OCRButtonIsBlocked}
              color={OCRButtonIsBlocked ? "grey" : "blue"}
              pending={pendingOCR}
              onClick={handleCalculateOCR}
            >
              Calculate
            </Button>
          )}
        </div>
        {group.stop_factors_approved && (
          <div className={styles.item}>
            <div className={styles.key}>Manual stop factors</div>
            <div
              className={classNames(
                styles.value,
                group.stop_factors_approved === "rejected"
                  ? styles.red
                  : group.stop_factors_approved === "review"
                  ? styles.yellow
                  : styles.green
              )}
            >
              {prettierStatus(group.stop_factors_approved)}
            </div>
          </div>
        )}
        <div className={styles.item}>
          <div className={styles.key}>Scoring</div>
          {group.scoring_approved ? (
            <div
              className={classNames(
                styles.value,
                group.scoring_approved === "rejected"
                  ? styles.red
                  : group.scoring_approved === "review"
                  ? styles.yellow
                  : styles.green
              )}
            >
              {prettierStatus(group.scoring_approved)} ({group.prediction?.toFixed(2)}%)
            </div>
          ) : (
            <Button
              blocked={ScoringButtonIsBlocked}
              color={ScoringButtonIsBlocked ? "grey" : "blue"}
              pending={pendingScoring}
              onClick={handleCalculateScoring}
            >
              Calculate
            </Button>
          )}
        </div>
      </div>
      {!clientId &&
        group.ocr_approved &&
        group.scoring_approved &&
        group.legal_case_approved &&
        group?.is_approved === "review" &&
        group?.expert_decision === null && (
          <div className={classNames(styles.result_box, styles.final_decision)}>
            <div className={styles.title}>Final decision</div>
            <Input
              value={decision}
              id="decision"
              title="Decision"
              placeholder="Select final decision"
              type="select"
              onSelect={(option) => setDecision(option)}
              options={["Approve", "Reject"]}
            />
            <Input
              id="commentary"
              title="Comment"
              value={commentary}
              type="textarea"
              onInput={(value) => setCommentary(value)}
            />
            {decision !== "" && commentary !== "" && (
              <Button
                color="violet"
                pending={pendingSelectRecommendation}
                onClick={handleFinalDecision}
              >
                Submit
              </Button>
            )}
          </div>
        )}
    </div>
  );
};

export default ResultBox;
