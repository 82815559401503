import React from "react";
import classNames from "classnames";

import ToggleBox from "components/toggle-box";
import { useClientContext } from "..";
import styles from "../application-group.module.scss";
import Input from "../components/input";

const LegalCase = () => {
  const { application, legalCase, setLegalCase } = useClientContext();

  if (application.legal_case_log)
    return (
      <div className={styles.legal_case_result}>
        <div className={styles.row}>
          <div className={styles.col}>New financial legal case</div>
          <div className={styles.col}>
            {application.legal_case_log.financial_legal_case === "approved"
              ? "N/A"
              : application.legal_case_log.financial_legal_case === "rejected"
              ? "Yes"
              : "No"}
          </div>
          <div
            className={classNames(
              styles.col,
              application.legal_case_log.financial_legal_case === "approved" &&
                styles.green,
              application.legal_case_log.financial_legal_case === "rejected" &&
                styles.red,
              application.legal_case_log.financial_legal_case === "review" &&
                styles.yellow
            )}
          >
            {application.legal_case_log.financial_legal_case === "approved"
              ? "Pass"
              : application.legal_case_log.financial_legal_case === "rejected"
              ? "Fail"
              : "Review"}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Bad credit reputation case</div>
          <div className={styles.col}>
            {application.legal_case_log.bad_credit_reputation_case === "approved"
              ? "N/A"
              : application.legal_case_log.bad_credit_reputation_case === "rejected"
              ? "Yes"
              : "No"}
          </div>
          <div
            className={classNames(
              styles.col,
              application.legal_case_log.bad_credit_reputation_case === "approved" &&
                styles.green,
              application.legal_case_log.bad_credit_reputation_case === "rejected" &&
                styles.red,
              application.legal_case_log.bad_credit_reputation_case === "review" &&
                styles.yellow
            )}
          >
            {application.legal_case_log.bad_credit_reputation_case === "approved"
              ? "Pass"
              : application.legal_case_log.bad_credit_reputation_case === "rejected"
              ? "Fail"
              : "Review"}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>Legal case date</div>
          <div className={styles.col}>
            {application.legal_case_log.legal_case_date ?? "N/A"}
          </div>
        </div>
      </div>
    );
  return (
    <div className={styles.legal_case_input}>
      <label className={styles.toggle_box}>
        <ToggleBox
          active={legalCase.hasLegalCase}
          onToggle={() =>
            setLegalCase({ ...legalCase, hasLegalCase: !legalCase.hasLegalCase })
          }
        />{" "}
        Client has legal case
      </label>
      {legalCase.hasLegalCase && (
        <>
          <Input
            title="New financial legal case"
            id="financial_legal_case"
            value={legalCase.financial_legal_case}
            type="select"
            onSelect={(option) =>
              setLegalCase({ ...legalCase, financial_legal_case: option })
            }
            options={["Yes", "No"]}
          />
          <Input
            title="Bad credit reputation case"
            id="bad_credit_reputation_case"
            value={legalCase.bad_credit_reputation_case}
            type="select"
            onSelect={(option) =>
              setLegalCase({ ...legalCase, bad_credit_reputation_case: option })
            }
            options={["Yes", "No"]}
          />
          <Input
            title="Legal case date"
            id="legal_case_date"
            value={legalCase.legal_case_date}
            type="string"
            realPlaceholder="DD/MM/YYYY"
            maxLength={10}
            onInput={(vaL) => {
              let value = vaL.replaceAll(/[^0-9]/g, "");

              if (value.length > 2) value = value.slice(0, 2) + "/" + value.slice(2);

              if (value.length > 5) value = value.slice(0, 5) + "/" + value.slice(5);

              setLegalCase({ ...legalCase, legal_case_date: value });
            }}
          />
        </>
      )}
    </div>
  );
};

export default LegalCase;
