import React from "react";
import Icon from "icons";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import classNames from "classnames";

import request from "request";
import global from "global";
import Loading from "components/loading";
import styles from "./application-group.module.scss";

const ApplicationGroupLayout = () => {
  const { id, clientId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [pagePending, setPagePending] = React.useState(false);
  const [group, setGroup] = React.useState<global.group_applications>();
  const [legalCaseList, setLegalCaseList] = React.useState<legalCase[]>([]);
  const [OCRFileList, setOCRFileList] = React.useState<OCRFile[]>([]);
  const [stop_factor_list, set_stop_factor_list] =
    React.useState<global.manual_factors[]>();

  React.useEffect(() => {
    setPagePending(true);
    request
      .get(`/applications/group/${id}`)
      .then((res) => res.data)
      .then((res) => {
        setGroup(res);
        setLegalCaseList(
          res.applications.map((app: global.application) => ({
            id: app.id,
            hasLegalCase: false,
            financial_legal_case: "",
            bad_credit_reputation_case: "",
            legal_case_date: "",
          }))
        );
        setOCRFileList(
          res.applications.map((app: global.application) => ({
            id: app.id,
          }))
        );
      })
      .finally(() => setPagePending(false));
  }, [id]);

  React.useEffect(() => {
    if (!group || !group.is_approved) return;
    const get_stop_factor_list = async () => {
      let fetched_stop_factors: global.manual_factors[] = [];
      for (let application of group.applications)
        await request.get(`applications/${application.id}`).then((res) =>
          fetched_stop_factors.push({
            ...res.data.manual_factors,
            id: res.data.application.id,
          })
        );
      set_stop_factor_list(fetched_stop_factors);
    };
    get_stop_factor_list();
  }, [group]);

  if (pagePending || !group)
    return (
      <div className={styles.page_loading}>
        <Loading />
      </div>
    );
  return (
    <div className={styles.applicationGroupLayout}>
      <div className={styles.navbar_wrap}>
        <div className={styles.navbar}>
          <NavLink
            end
            type="button"
            draggable={false}
            className={styles.link_wrap}
            to={`/application-group/${id}`}
          >
            {({ isActive }) => (
              <div className={classNames(styles.link, isActive && styles.active)}>
                <Icon className={styles.icon} name={isActive ? "homeFilled" : "home"} />
                Group info
              </div>
            )}
          </NavLink>
        </div>
        <div className={styles.navbar}>
          {group?.applications.map((application, index) => (
            <NavLink
              key={application.id}
              type="button"
              draggable={false}
              to={`/application-group/${id}/client/${application.id}`}
            >
              {({ isActive }) => {
                const lastPath =
                  location.pathname.split("/")[location.pathname.split("/").length - 1];
                return (
                  <button
                    className={styles.link_wrap}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(
                        `/application-group/${id}/client/${application.id}${
                          lastPath === "ocr" || lastPath === "scoring"
                            ? "/" + lastPath
                            : ""
                        }`
                      );
                    }}
                  >
                    <div className={classNames(styles.link, isActive && styles.active)}>
                      <Icon
                        className={styles.icon}
                        name={isActive ? "personFilled" : "person"}
                      />
                      Client {index + 1}
                    </div>
                  </button>
                );
              }}
            </NavLink>
          ))}
        </div>
        <div className={styles.navbar}>
          {group.is_approved !== null &&
            group.scoring_approved !== null &&
            group.legal_case_approved !== null && (
              <NavLink
                type="button"
                draggable={false}
                className={styles.link_wrap}
                to={`/application-group/${id}/result`}
              >
                {({ isActive }) => (
                  <div className={classNames(styles.link, isActive && styles.active)}>
                    <Icon
                      className={styles.icon}
                      name={isActive ? "verifyFill" : "verify"}
                    />
                    Result
                  </div>
                )}
              </NavLink>
            )}
          <button
            type="button"
            className={styles.link_wrap}
            onClick={() => navigate("/group-loans")}
          >
            <div className={styles.link}>
              <Icon className={styles.icon} name="longArrowLeft" />
              Back
            </div>
          </button>
        </div>
      </div>
      <Outlet
        context={{
          application: group?.applications.find(
            (application) => application.id === Number(clientId)
          ),
          group,
          setGroup,
          stop_factors: stop_factor_list?.find((s) => s.id === Number(clientId)),
          stop_factor_list,
          legalCaseList,
          legalCase: legalCaseList.find((legalCase) => legalCase.id === Number(clientId)),
          setLegalCase: (newL: legalCase) =>
            setLegalCaseList(legalCaseList.map((l) => (l.id === newL.id ? newL : l))),
          OCRFileList,
          OCRFile: OCRFileList.find((OCRFile) => OCRFile.id === Number(clientId)),
          setOCRFile: (newO: OCRFile) =>
            setOCRFileList(OCRFileList.map((l) => (l.id === newO.id ? newO : l))),
        }}
      />
    </div>
  );
};

type OCRFile = {
  id: number;
  file?: File;
};

type legalCase = {
  id: number;
  hasLegalCase: boolean;
  financial_legal_case: string;
  bad_credit_reputation_case: string;
  legal_case_date: string;
};

export interface ClientContextType {
  application: global.application;
  group: global.group_applications;
  setGroup: (value: global.group_applications) => void;
  OCRFileList: OCRFile[];
  OCRFile: OCRFile;
  stop_factors: global.manual_factors;
  stop_factor_list?: global.manual_factors[];
  setOCRFile: (OCRFile: OCRFile) => void;
  legalCaseList: legalCase[];
  legalCase: legalCase;
  setLegalCase: (legalCase: legalCase) => void;
}

export const useClientContext = () => {
  return useOutletContext<ClientContextType>();
};

export default ApplicationGroupLayout;
