import classNames from "classnames";
import React from "react";

import styles from "./button-loader.module.scss";

const ButtonLoader = ({ theme = "white" }: { theme?: "blue" | "white" }) => {
  return (
    <div className={classNames(styles.buttonLoader, styles[theme])}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ButtonLoader;
