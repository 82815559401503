import classNames from "classnames";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import request from "request";
import mainTranscript from "utils/transcript";
import { useClientContext } from "..";
import styles from "../application-group.module.scss";
import Input from "../components/input";
import transcript from "../utils/transcript";

let timeout: NodeJS.Timeout;
const Scoring = () => {
  const { group, application, stop_factors, setGroup } = useClientContext();
  const { id, clientId } = useParams();

  const [form, setForm] = React.useState({
    id: "",
    age: "",
    gender: "",
    family_status: "",
    dependents: "",
    nationality: "",
    address: "",
    repeated_client: "",
    prior_loans: "",
    remaining_payments: "",
    postpone_payments: "",
    early_settlement: "",
    dual_loans: "",
    max_days_late_payments: "",
    client_status_gurantor: "",
    family_members_loans: "",
    family_members_delinquent: "",
    project_net_monthly_income: "",
    additional_income: "",
    monthly_payment_for_active_loans: "",
    residence_type: "",
    transport: "",
    transport_manufacturing_year: "",
    employment_status: "",
    employment_level: "",
    work_experience: "",
    phone_os: "",
  });

  useEffect(() => {
    setForm((prev) =>
      String(application.id) === prev.id
        ? prev
        : {
            id: String(application.id),
            age: String(application.scoring_log?.age ?? ""),
            gender: "Female",
            family_status:
              transcript.family_status(application.scoring_log?.family_status ?? "") ??
              "",
            dependents: String(application.scoring_log?.dependants ?? ""),
            nationality: application.scoring_log?.nationality ?? "",
            address: application.scoring_log?.address ?? "",
            repeated_client:
              transcript.repeated_client(
                application.scoring_log?.repeated_client ?? ""
              ) ?? "",
            prior_loans: String(application.scoring_log?.prior_loans ?? ""),
            remaining_payments: String(application.scoring_log?.remaining_payments ?? ""),
            postpone_payments: String(application.scoring_log?.postpone_payments ?? ""),
            early_settlement: String(application.scoring_log?.early_settlement ?? ""),
            dual_loans:
              application.scoring_log?.dual_loans !== undefined &&
              application.scoring_log?.dual_loans !== null
                ? application.scoring_log?.dual_loans
                  ? "Yes"
                  : "No"
                : "",
            max_days_late_payments: String(
              application.scoring_log?.max_days_late_payments ?? ""
            ),
            client_status_gurantor:
              application.scoring_log?.client_status_gurantor !== undefined &&
              application.scoring_log?.client_status_gurantor !== null
                ? application.scoring_log?.client_status_gurantor
                  ? "Yes"
                  : "No"
                : "",
            family_members_loans:
              application.scoring_log?.family_members_loans !== undefined &&
              application.scoring_log?.family_members_loans !== null
                ? application.scoring_log?.family_members_loans
                  ? "Yes"
                  : "No"
                : "",
            family_members_delinquent:
              application.scoring_log?.family_members_delinquent !== undefined &&
              application.scoring_log?.family_members_delinquent !== null
                ? application.scoring_log?.family_members_delinquent
                  ? "Yes"
                  : "No"
                : "",
            project_net_monthly_income: String(
              application.scoring_log?.project_net_monthly_income ?? ""
            ),
            additional_income: String(application.scoring_log?.additional_income ?? ""),
            monthly_payment_for_active_loans: String(
              application.scoring_log?.monthly_payment_for_active_loans ?? ""
            ),
            residence_type: application.scoring_log?.residence_type ?? "",
            transport: application.scoring_log?.transport ?? "",
            transport_manufacturing_year: String(
              application.scoring_log?.transport_manufacturing_year ?? ""
            ),
            employment_status: application.scoring_log?.employment_status ?? "",
            employment_level: application.scoring_log?.employment_level ?? "",
            work_experience: String(application.scoring_log?.work_experience ?? ""),
            phone_os: application.scoring_log?.phone_os ?? "",
          }
    );
  }, [application]);

  const handleChangeForm = (key: keyof typeof form, value: string) => {
    clearTimeout(timeout);
    const newForm = { ...form };
    newForm[key] = value;
    setForm(newForm);
    timeout = setTimeout(() => {
      request
        .put(`/applications/group/${id}/${clientId}`, {
          scoring_data: {
            gender: application.scoring_log?.gender,
            city: application.scoring_log?.city,
            dependants: newForm.dependents !== "" ? Number(newForm.dependents) : null,
            family_status: transcript.family_status(newForm.family_status) ?? null,
            age: newForm.age !== "" ? Number(newForm.age) : null,
            prior_loans: newForm.prior_loans !== "" ? Number(newForm.prior_loans) : null,
          },
          collected_data: {
            active_loans: application.scoring_log?.active_loans,
            branch: application.scoring_log?.branch,
            // remaining_payments: application.scoring_log?.remaining_payments,
            phone_os: newForm.phone_os !== "" ? newForm.phone_os : null,
            transport: newForm.transport !== "" ? newForm.transport : null,
            residence_type: newForm.residence_type !== "" ? newForm.residence_type : null,
            employment_status:
              newForm.employment_status !== "" ? newForm.employment_status : null,
            employment_level:
              newForm.employment_status === "None"
                ? "None"
                : newForm.employment_level !== ""
                ? newForm.employment_level
                : null,
            work_experience:
              newForm.employment_status === "None"
                ? 0
                : newForm.work_experience !== ""
                ? Number(newForm.work_experience)
                : null,
            transport_manufacturing_year:
              newForm.transport_manufacturing_year !== ""
                ? Number(newForm.transport_manufacturing_year)
                : null,
            nationality: newForm.nationality !== "" ? newForm.nationality : null,
            address: newForm.address !== "" ? newForm.address : null,
            repeated_client: transcript.repeated_client(newForm.repeated_client) ?? null,
            remaining_payments:
              newForm.repeated_client === "New"
                ? "0"
                : newForm.remaining_payments !== ""
                ? Number(newForm.remaining_payments)
                : null,
            postpone_payments:
              newForm.repeated_client === "New"
                ? "0"
                : newForm.postpone_payments !== ""
                ? Number(newForm.postpone_payments)
                : null,
            early_settlement:
              newForm.repeated_client === "New"
                ? "0"
                : newForm.early_settlement !== ""
                ? Number(newForm.early_settlement)
                : null,
            project_net_monthly_income:
              newForm.project_net_monthly_income !== ""
                ? Number(newForm.project_net_monthly_income)
                : null,
            additional_income:
              newForm.additional_income !== "" ? Number(newForm.additional_income) : null,
            monthly_payment_for_active_loans:
              newForm.monthly_payment_for_active_loans !== ""
                ? Number(newForm.monthly_payment_for_active_loans)
                : null,
          },
          stop_factors: {
            loan_purpose: application.scoring_log?.loan_purpose,
            group_loan: application.scoring_log?.group_loan,
            max_days_late_payments:
              newForm.max_days_late_payments !== ""
                ? Number(newForm.max_days_late_payments)
                : null,
            client_status_gurantor:
              newForm.client_status_gurantor !== ""
                ? newForm.client_status_gurantor === "Yes"
                : null,
            dual_loans: newForm.dual_loans !== "" ? newForm.dual_loans === "Yes" : null,
            family_members_loans:
              newForm.family_members_loans !== ""
                ? newForm.family_members_loans === "Yes"
                : null,
            family_members_delinquent:
              newForm.family_members_delinquent !== ""
                ? newForm.family_members_delinquent === "Yes"
                : null,
          },
        })
        .then(() => {
          request
            .get(`/applications/group/${id}`)
            .then((res) => res.data)
            .then((res) => setGroup(res));
        });
    }, 1000);
  };

  if (group.scoring_approved)
    return (
      <div className={styles.scoring_result}>
        <div className={styles.section}>
          <div className={styles.title}>Credit data</div>
          <div className={styles.item}>
            <div className={styles.key}>Amount</div>
            <div className={styles.value}>{application.amount} JOD</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Duration</div>
            <div className={styles.value}>{application.duration} mon.</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Interest rate (annual)</div>
            <div className={styles.value}>
              {(application.interest_rate * 100).toFixed(2)} %
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Threshold</div>
            <div className={styles.value}>
              {Number(application.scoring_log?.threshold) * 100} %
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.title}>Client data</div>
          <div className={styles.item}>
            <div className={styles.key}>Gender</div>
            <div className={styles.value}>
              {transcript.gender(application.scoring_log?.gender ?? "")}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Family status</div>
            <div className={styles.value}>
              {transcript.family_status(application.scoring_log?.family_status ?? "")}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Dependants</div>
            <div className={styles.value}>{application.scoring_log?.dependants}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Prior loans</div>
            <div className={styles.value}>{application.scoring_log?.prior_loans}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Client residence</div>
            <div className={styles.value}>
              {mainTranscript.city.getValueFromKey(application.scoring_log?.city ?? "")}
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.title}>Alternative data</div>
          <div className={styles.item}>
            <div className={styles.key}>Transport manufacturing year</div>
            <div className={styles.value}>
              {application.scoring_log?.transport_manufacturing_year}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Transport</div>
            <div className={styles.value}>{application.scoring_log?.transport}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Employment status</div>
            <div className={styles.value}>
              {application.scoring_log?.employment_status}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Employment level</div>
            <div className={styles.value}>
              {application.scoring_log?.employment_level}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Residence type</div>
            <div className={styles.value}>{application.scoring_log?.residence_type}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Work experience</div>
            <div className={styles.value}>{application.scoring_log?.work_experience}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Phone OS</div>
            <div className={styles.value}>{application.scoring_log?.phone_os}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Branch</div>
            <div className={styles.value}>
              {mainTranscript.branch.getValueFromKey(
                application.scoring_log?.branch ?? ""
              )}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Nationality</div>
            <div className={styles.value}>{application.scoring_log?.nationality}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Address</div>
            <div className={styles.value}>{application.scoring_log?.address}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Active loans</div>
            <div className={styles.value}>{application.scoring_log?.active_loans}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Repeated client?</div>
            <div className={styles.value}>{application.scoring_log?.repeated_client}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Remaining payments</div>
            <div className={styles.value}>
              {application.scoring_log?.remaining_payments}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Postpone payments</div>
            <div className={styles.value}>
              {application.scoring_log?.postpone_payments}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Early settlement</div>
            <div className={styles.value}>
              {application.scoring_log?.early_settlement}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Project net monthly income</div>
            <div className={styles.value}>
              {application.scoring_log?.project_net_monthly_income} JOD
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Additional income</div>
            <div className={styles.value}>
              {application.scoring_log?.additional_income} JOD
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Monthly payment for active loans</div>
            <div className={styles.value}>
              {application.scoring_log?.monthly_payment_for_active_loans} JOD
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Monthly payment</div>
            <div className={styles.value}>
              {application.scoring_log?.monthly_payment} JOD
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.title}>Manual stop factors</div>
          <div className={styles.row}>
            <div className={styles.col}>Prohibited loan purpose</div>
            <div className={styles.col}>
              {application.scoring_log?.loan_purpose ? "Yes" : "No"}
            </div>
            <div
              className={classNames(
                styles.col,
                stop_factors?.loan_purpose === "approved"
                  ? styles.green
                  : stop_factors?.loan_purpose === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {stop_factors?.loan_purpose === "approved"
                ? "Approved"
                : stop_factors?.loan_purpose === "rejected"
                ? "Rejected"
                : "Review"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>Age</div>
            <div className={styles.col}>{application.scoring_log?.age} y/o</div>
            <div
              className={classNames(
                styles.col,
                stop_factors?.age === "approved"
                  ? styles.green
                  : stop_factors?.age === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {stop_factors?.age === "approved"
                ? "Approved"
                : stop_factors?.age === "rejected"
                ? "Rejected"
                : "Review"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>Dual loans</div>
            <div className={styles.col}>
              {application.scoring_log?.dual_loans ? "Yes" : "No"}
            </div>
            <div
              className={classNames(
                styles.col,
                stop_factors?.dual_loans === "approved"
                  ? styles.green
                  : stop_factors?.dual_loans === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {stop_factors?.dual_loans === "approved"
                ? "Approved"
                : stop_factors?.dual_loans === "rejected"
                ? "Rejected"
                : "Review"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>Max days late payments</div>
            <div className={styles.col}>
              {application.scoring_log?.max_days_late_payments}
            </div>
            <div
              className={classNames(
                styles.col,
                stop_factors?.max_days_late_payments === "approved"
                  ? styles.green
                  : stop_factors?.max_days_late_payments === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {stop_factors?.max_days_late_payments === "approved"
                ? "Approved"
                : stop_factors?.max_days_late_payments === "rejected"
                ? "Rejected"
                : "Review"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              Client is already a guarantor for another active loan?
            </div>
            <div className={styles.col}>
              {application.scoring_log?.guarantor_for_another_loan ? "Yes" : "No"}
            </div>
            <div
              className={classNames(
                styles.col,
                stop_factors?.guarantor_for_another_loan === "approved"
                  ? styles.green
                  : stop_factors?.guarantor_for_another_loan === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {stop_factors?.guarantor_for_another_loan === "approved"
                ? "Approved"
                : stop_factors?.guarantor_for_another_loan === "rejected"
                ? "Rejected"
                : "Review"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>Family members loans (in the same house)</div>
            <div className={styles.col}>
              {application.scoring_log?.family_members_loans ? "Yes" : "No"}
            </div>
            <div
              className={classNames(
                styles.col,
                stop_factors?.family_members_loans === "approved"
                  ? styles.green
                  : stop_factors?.family_members_loans === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {stop_factors?.family_members_loans === "approved"
                ? "Approved"
                : stop_factors?.family_members_loans === "rejected"
                ? "Rejected"
                : "Review"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              Family members delinquent (in the same house)
            </div>
            <div className={styles.col}>
              {application.scoring_log?.family_members_delinquent ? "Yes" : "No"}
            </div>
            <div
              className={classNames(
                styles.col,
                stop_factors?.family_members_delinquent === "approved"
                  ? styles.green
                  : stop_factors?.family_members_delinquent === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {stop_factors?.family_members_delinquent === "approved"
                ? "Approved"
                : stop_factors?.family_members_delinquent === "rejected"
                ? "Rejected"
                : "Review"}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>Client DBR</div>
            <div className={styles.col}>
              {(Number(application.scoring_log?.client_dbr) * 100)?.toFixed(2)} %
            </div>
            <div
              className={classNames(
                styles.col,
                stop_factors?.client_dbr === "approved"
                  ? styles.green
                  : stop_factors?.client_dbr === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {stop_factors?.client_dbr === "approved"
                ? "Approved"
                : stop_factors?.client_dbr === "rejected"
                ? "Rejected"
                : "Review"}
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.scoring}>
      <div className={styles.section}>
        <div className={styles.title}>Client data</div>
        <Input
          title="Age"
          value={form.age}
          step={1}
          type="integer"
          onInput={(value) => handleChangeForm("age", value)}
        />
        <Input
          title="Gender"
          id="gender"
          value={form.gender}
          type="select"
          disabled={true}
          options={["Female", "Male"]}
          onSelect={(option) => handleChangeForm("gender", option)}
        />
        <Input
          title="Family status"
          id="family_status"
          value={form.family_status}
          type="select"
          options={["Divorced", "Married", "Single", "Widowed"]}
          onSelect={(option) => handleChangeForm("family_status", option)}
        />
        <Input
          title="Dependants"
          value={form.dependents}
          step={1}
          min={0}
          max={97}
          type="integer"
          onInput={(value) => handleChangeForm("dependents", value)}
        />
        <Input
          title="Nationality"
          id="nationality"
          value={form.nationality}
          type="select"
          hasFilterText
          options={[
            "Saudi Arabia",
            "Iran",
            "Qatar",
            "Jordan",
            "Yemen",
            "Palestine",
            "Lybia",
            "Afghanistan",
            "UAE",
            "Israel",
            "Syria",
            "Oman",
            "Egypt",
            "Morocco",
            "Cyprus",
            "Sudan",
            "Lebanon",
            "Kuwait",
            "Iraq",
            "Bahrain",
            "Turkey",
            "Tunisia",
            "Pakistan",
            "Djibout",
          ]}
          onSelect={(option) => handleChangeForm("nationality", option)}
        />
        <Input
          title="Address"
          value={form.address}
          type="string"
          onInput={(value) => handleChangeForm("address", value)}
        />
        <Input
          title="Repeated client?"
          id="repeated_client"
          value={form.repeated_client}
          type="select"
          options={["Repeated", "New"]}
          onSelect={(option) => handleChangeForm("repeated_client", option)}
        />
        <Input
          title="Prior loans"
          value={form.prior_loans}
          min={1}
          max={18}
          step={1}
          type="integer"
          onInput={(value) => handleChangeForm("prior_loans", value)}
        />
        <Input
          title="Remaining payments"
          value={form.repeated_client === "New" ? "0" : form.remaining_payments}
          step={1}
          type="float"
          disabled={form.repeated_client === "New"}
          onInput={(value) => handleChangeForm("remaining_payments", value)}
        />
        <Input
          title="Postpone payments"
          value={form.repeated_client === "New" ? "0" : form.postpone_payments}
          step={1}
          type="float"
          disabled={form.repeated_client === "New"}
          onInput={(value) => handleChangeForm("postpone_payments", value)}
        />
        <Input
          title="Early settlement"
          value={form.repeated_client === "New" ? "0" : form.early_settlement}
          step={1}
          type="integer"
          disabled={form.repeated_client === "New"}
          onInput={(value) => handleChangeForm("early_settlement", value)}
        />
        <Input
          title="Dual loans"
          id="dual_loans"
          value={form.dual_loans}
          type="select"
          options={["Yes", "No"]}
          onSelect={(option) => handleChangeForm("dual_loans", option)}
        />
        <Input
          title="Max days late payments"
          value={form.max_days_late_payments}
          step={1}
          type="integer"
          onInput={(value) => handleChangeForm("max_days_late_payments", value)}
        />
        <Input
          title="Client is already a guarantor for another active loan?"
          id="client_status_gurantor"
          value={form.client_status_gurantor}
          type="select"
          options={["Yes", "No"]}
          onSelect={(option) => handleChangeForm("client_status_gurantor", option)}
        />
        <Input
          title="Family members loans (in the same house)"
          id="family_members_loans"
          value={form.family_members_loans}
          type="select"
          options={["Yes", "No"]}
          onSelect={(option) => handleChangeForm("family_members_loans", option)}
        />
        <Input
          title="Family members delinquent (in the same house)"
          id="family_members_delinquent"
          value={form.family_members_delinquent}
          type="select"
          options={["Yes", "No"]}
          onSelect={(option) => handleChangeForm("family_members_delinquent", option)}
        />
        <Input
          title="Project net monthly income"
          value={form.project_net_monthly_income}
          step={1}
          type="float"
          onInput={(value) => handleChangeForm("project_net_monthly_income", value)}
        />
        <Input
          title="Additional income"
          value={form.additional_income}
          step={1}
          type="float"
          onInput={(value) => handleChangeForm("additional_income", value)}
        />
        <Input
          title="Monthly payment for active loans"
          value={form.monthly_payment_for_active_loans}
          step={1}
          type="float"
          onInput={(value) => handleChangeForm("monthly_payment_for_active_loans", value)}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Alternative data</div>
        <Input
          title="Residence type"
          id="residence_type"
          value={form.residence_type}
          type="select"
          options={["Rent", "Ownership", "On loan", "Other", "None"]}
          onSelect={(option) => handleChangeForm("residence_type", option)}
        />
        <Input
          title="Transport"
          id="transport"
          value={form.transport}
          type="select"
          options={["Rent", "Ownership", "On loan", "Other", "None"]}
          onSelect={(option) => handleChangeForm("transport", option)}
        />
        <Input
          title="Transport manufacturing year"
          value={form.transport_manufacturing_year}
          step={1}
          min={1900}
          max={new Date().getFullYear() + 2}
          type="integer"
          onInput={(value) => handleChangeForm("transport_manufacturing_year", value)}
        />
        <Input
          title="Employment status"
          id="employment_status"
          value={form.employment_status}
          type="select"
          options={["Employed", "Unemployed", "Other", "None"]}
          onSelect={(option) => handleChangeForm("employment_status", option)}
        />
        <Input
          title="Employment level"
          id="employment_level"
          value={form.employment_status === "None" ? "None" : form.employment_level}
          type="select"
          disabled={form.employment_status === "None"}
          options={[
            "Government agency",
            "Public organization",
            "Private company",
            "Individual entrepreneur",
            "Other",
            "None",
          ]}
          onSelect={(option) => handleChangeForm("employment_level", option)}
        />
        <Input
          title="Work experience"
          value={form.employment_status === "None" ? "0" : form.work_experience}
          step={1}
          min={0}
          max={97}
          type="integer"
          disabled={form.employment_status === "None"}
          onInput={(value) => handleChangeForm("work_experience", value)}
          unit="mon."
        />
        <Input
          title="Phone OS"
          id="phone_os"
          value={form.phone_os}
          type="select"
          options={["iOS", "Android", "Other", "None"]}
          onSelect={(option) => handleChangeForm("phone_os", option)}
        />
      </div>
    </div>
  );
};

export default Scoring;
