import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { createBrowserHistory } from "history";

import baseURL from "utils/base-url";

export const history = createBrowserHistory();

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token: string | null = localStorage.getItem("token") || null;

  let headers = config.headers ?? {};

  if (token) headers.Authorization = `Bearer ${token}`;

  const newConfig: InternalAxiosRequestConfig = {
    ...config,
    headers,
  };

  return newConfig;
});

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.code === "ERR_NETWORK") toast.error("Server error");
    // else if (error.response?.status === 422) {
    // (error.response.data as any)?.detail.forEach((log: any) => {
    //   toast.error(log.msg);
    // });
    // }
    else if (error.response?.status === 403 || error.response?.status === 401) {
      localStorage.removeItem("token");
      history.push("/login");
    }
    return Promise.reject(error);
  }
);

export const request = instance;

export default request;
