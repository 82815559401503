import React from "react";
import global from "global";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import request from "request";
import Button from "components/button";
import Input from "components/input";
import ResultBox from "components/result-box";
import { useDispatch, useSelector } from "store/hooks";
import prettierNumber from "utils/prettier-number";
import transcript from "utils/transcript";
import styles from "./scoring.module.scss";
import { stopFactorStatusTranscript } from "../../utils/prettier-status";

const Scoring = () => {
  const { id } = useParams();
  const contextApplication = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const [macrodata, setMacrodata] = React.useState<global.macrodata>();

  const [pending, setPending] = React.useState(false);

  const [formValid, setFormValid] = React.useState(false);

  const [age, setAge] = React.useState("");
  const [priorLoans, setPriorLoans] = React.useState("");
  const [dependents, setDependents] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [active_loans, setActive_loans] = React.useState("");
  const [remaining_payments, setRemaining_payments] = React.useState("");
  const [postpone_payments, setPostpone_payments] = React.useState("");
  const [early_settlement, setEarly_settlement] = React.useState("");
  const [branch, setBranch] = React.useState({
    key: "",
    value: "",
  });
  const [group_loan, setGroup_loan] = React.useState({
    key: "",
    value: "",
  });
  const [loan_purpose, setLoan_purpose] = React.useState({
    key: "",
    value: "",
  });
  const [nationality, setNationality] = React.useState({
    key: "",
    value: "",
  });
  const [max_days_late_payments, setMax_days_late_payments] = React.useState("");
  const [project_net_monthly_income, setProject_net_monthly_income] = React.useState("");
  const [additional_income, setAdditional_income] = React.useState("");
  const [monthly_payment_for_active_loans, setMonthly_payment_for_active_loans] =
    React.useState("");
  const [guarantor_age, setGuarantor_age] = React.useState("");
  const [guarantor_income, setGuarantor_income] = React.useState("");
  const [guarantor_additional_income, setGuarantor_additional_income] =
    React.useState("");
  const [guarantor_total_monthly_payments, setGuarantor_total_monthly_payments] =
    React.useState("");
  const [repeated_client, setRepeated_client] = React.useState({
    key: "",
    value: "",
  });
  const [dual_loans, setDual_loans] = React.useState({
    key: "",
    value: "",
  });
  const [client_status_gurantor, setClient_status_gurantor] = React.useState({
    key: "",
    value: "",
  });
  const [family_members_loans, setFamily_members_loans] = React.useState({
    key: "",
    value: "",
  });
  const [family_members_delinquent, setFamily_members_delinquent] = React.useState({
    key: "",
    value: "",
  });
  const [guarantor_has_active_loan, setGuarantor_has_active_loan] = React.useState({
    key: "",
    value: "",
  });
  const [guarantor_for_another_loan, setGuarantor_for_another_loan] = React.useState({
    key: "",
    value: "",
  });
  const [gender, setGender] = React.useState({
    key: "",
    value: "",
  });
  const [city, setCity] = React.useState({
    key: "",
    value: "",
  });
  const [maritalStatus, setMaritalStatus] = React.useState({
    key: "",
    value: "",
  });
  const [phoneOS, setPhoneOS] = React.useState({
    key: "",
    value: "",
  });
  const [transport, setTransport] = React.useState({
    key: "",
    value: "",
  });
  const [residenceType, setResidenceType] = React.useState({
    key: "",
    value: "",
  });
  const [employmentLevel, setEmploymentLevel] = React.useState({
    key: "",
    value: "",
  });
  const [employmentStatus, setEmploymentStatus] = React.useState({
    key: "",
    value: "",
  });
  const [workExperience, setWorkExperience] = React.useState("");
  const [transportManufacturingYear, setTransportManufacturingYear] = React.useState("");

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;
    setPending(true);
    request
      .post(`/applications/${id}/scoring`, {
        scoring_data: {
          dependants: Number(dependents),
          family_status: maritalStatus.key,
          gender: gender.key,
          age: Number(age),
          prior_loans: Number(priorLoans),
          city: city.key,
          monthly_income: Number(project_net_monthly_income) + Number(additional_income),
        },
        collected_data: {
          branch: branch.key,
          phone_os: phoneOS.key,
          transport: transport.key,
          residence_type: residenceType.key,
          employment_level: employmentLevel.key,
          employment_status: employmentStatus.key,
          work_experience: Number(workExperience),
          transport_manufacturing_year: Number(transportManufacturingYear),
          nationality: nationality.key,
          address,
          active_loans: Number(active_loans),
          repeated_client: repeated_client.key,
          remaining_payments: Number(remaining_payments),
          postpone_payments: Number(postpone_payments),
          early_settlement: Number(early_settlement),
          project_net_monthly_income: Number(project_net_monthly_income),
          additional_income: Number(additional_income),
          monthly_payment_for_active_loans: Number(monthly_payment_for_active_loans),
          guarantor_income: guarantor_income === "" ? null : Number(guarantor_income),
          guarantor_additional_income:
            guarantor_additional_income === ""
              ? null
              : Number(guarantor_additional_income),
          guarantor_total_monthly_payments:
            guarantor_total_monthly_payments === ""
              ? null
              : Number(guarantor_total_monthly_payments),
        },
        stop_factors: {
          group_loan: Boolean(Number(group_loan.key)),
          loan_purpose: Boolean(Number(loan_purpose.key)),
          dual_loans: Boolean(Number(dual_loans.key)),
          max_days_late_payments: Number(max_days_late_payments),
          client_status_gurantor: Boolean(Number(client_status_gurantor.key)),
          family_members_loans: Boolean(Number(family_members_loans.key)),
          family_members_delinquent: Boolean(Number(family_members_delinquent.key)),
          guarantor_age: guarantor_age === "" ? null : Number(guarantor_age),
          guarantor_has_active_loan:
            guarantor_has_active_loan.key === ""
              ? null
              : Boolean(Number(guarantor_has_active_loan.key)),
          guarantor_for_another_loan:
            guarantor_for_another_loan.key === ""
              ? null
              : Boolean(Number(guarantor_for_another_loan.key)),
        },
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(
          () => dispatch.application.FETCH_APPLICATION_BY_ID(id),
          1000
        );
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  React.useEffect(() => {
    setFormValid(
      age !== "" &&
        priorLoans !== "" &&
        dependents !== "" &&
        address !== "" &&
        active_loans !== "" &&
        remaining_payments !== "" &&
        postpone_payments !== "" &&
        early_settlement !== "" &&
        branch.key !== "" &&
        group_loan.key !== "" &&
        loan_purpose.key !== "" &&
        nationality.key !== "" &&
        max_days_late_payments !== "" &&
        project_net_monthly_income !== "" &&
        additional_income !== "" &&
        monthly_payment_for_active_loans !== "" &&
        ((guarantor_age !== "" &&
          guarantor_for_another_loan.key !== "" &&
          guarantor_has_active_loan.key !== "" &&
          guarantor_income !== "" &&
          guarantor_additional_income !== "" &&
          guarantor_total_monthly_payments !== "") ||
          group_loan.key === "1") &&
        repeated_client.key !== "" &&
        dual_loans.key !== "" &&
        client_status_gurantor.key !== "" &&
        family_members_loans.key !== "" &&
        family_members_delinquent.key !== "" &&
        gender.key !== "" &&
        city.key !== "" &&
        maritalStatus.key !== "" &&
        phoneOS.key !== "" &&
        transport.key !== "" &&
        residenceType.key !== "" &&
        employmentLevel.key !== "" &&
        employmentStatus.key !== "" &&
        workExperience !== "" &&
        transportManufacturingYear !== ""
    );
  }, [
    active_loans,
    additional_income,
    address,
    age,
    branch.key,
    city.key,
    client_status_gurantor.key,
    dependents,
    dual_loans.key,
    early_settlement,
    employmentLevel.key,
    employmentStatus.key,
    family_members_delinquent.key,
    family_members_loans.key,
    gender.key,
    group_loan.key,
    guarantor_age,
    guarantor_for_another_loan.key,
    guarantor_has_active_loan.key,
    guarantor_income,
    guarantor_total_monthly_payments,
    guarantor_additional_income,
    loan_purpose.key,
    maritalStatus.key,
    max_days_late_payments,
    monthly_payment_for_active_loans,
    nationality.key,
    phoneOS.key,
    postpone_payments,
    priorLoans,
    project_net_monthly_income,
    remaining_payments,
    repeated_client.key,
    residenceType.key,
    transport.key,
    transportManufacturingYear,
    workExperience,
  ]);

  React.useEffect(() => {
    if (employmentStatus.key === "None") {
      setEmploymentLevel({
        value: "None",
        key: "None",
      });
      setWorkExperience("0");
    }
  }, [employmentStatus.key]);

  React.useEffect(() => {
    if (repeated_client.key === "new") {
      setRemaining_payments("0");
      setPostpone_payments("0");
      setEarly_settlement("0");
      setWorkExperience("0");
    }
  }, [repeated_client.key]);

  React.useEffect(() => {
    if (group_loan.key === "1") {
      setGuarantor_income("");
      setGuarantor_age("");
      setGuarantor_additional_income("");
      setGuarantor_total_monthly_payments("");
      setGuarantor_has_active_loan({
        key: "",
        value: "",
      });
      setGuarantor_for_another_loan({
        key: "",
        value: "",
      });
      setGender({
        key: "female",
        value: "Female",
      });
    }
  }, [group_loan.key]);

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);

  return (
    <form className={styles.home}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Credit data</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>Amount</div>
              <div className={styles.value}>
                {prettierNumber(contextApplication.data?.amount ?? 0)} JOD
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Duration</div>
              <div className={styles.value}>{contextApplication.data?.duration} mon.</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Interest rate (annual)</div>
              <div className={styles.value}>
                {(Number(contextApplication.data?.interest_rate) * 100).toFixed()} %
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Threshold</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.threshold
                  ? (contextApplication.data?.scoring_log?.threshold * 100).toFixed()
                  : Math.round((macrodata?.threshold ?? 0) * 100)}{" "}
                %
              </div>
            </div>
          </div>
        </div>
        {!!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Client data</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Gender</div>
                  <div className={styles.value}>
                    {transcript.genderСode.getValueFromKey(
                      contextApplication.data.scoring_log.gender
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Family status</div>
                  <div className={styles.value}>
                    {transcript.maritalStatusEng.getValueFromKey(
                      contextApplication.data.scoring_log.family_status
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Dependants</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.dependants}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Prior loans</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.prior_loans}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Client residence</div>
                  <div className={styles.value}>
                    {transcript.city.getValueFromKey(
                      contextApplication.data.scoring_log.city
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Alternative data</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Transport manufacturing year</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.transport_manufacturing_year}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Transport</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.transport}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Employment status</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.employment_status}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Employment level</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.employment_level}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Residence type</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.residence_type}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Work experience</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.work_experience}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Phone OS</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.phone_os}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Branch</div>
                  <div className={styles.value}>
                    {transcript.branch.getValueFromKey(
                      contextApplication.data.scoring_log.branch
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Nationality</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.nationality}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Address</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.address}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Active loans</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.active_loans}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Repeated client?</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.repeated_client}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Remaining payments</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.remaining_payments}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Postpone payments</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.postpone_payments}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Early settlement</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.early_settlement}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Project net monthly income</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.project_net_monthly_income} JOD
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Additional income</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.additional_income} JOD
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Monthly payment for active loans</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.monthly_payment_for_active_loans}{" "}
                    JOD
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Monthly payment</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.monthly_payment.toFixed(1)} JOD
                  </div>
                </div>
                {contextApplication.data.scoring_log.guarantor_income !== null && (
                  <div className={styles.item}>
                    <div className={styles.key}>Guarantor income</div>
                    <div className={styles.value}>
                      {contextApplication.data.scoring_log.guarantor_income} JOD
                    </div>
                  </div>
                )}
                {contextApplication.data.scoring_log.guarantor_total_monthly_payments !==
                  null && (
                  <div className={styles.item}>
                    <div className={styles.key}>Guarantor total monthly payments</div>
                    <div className={styles.value}>
                      {
                        contextApplication.data.scoring_log
                          .guarantor_total_monthly_payments
                      }{" "}
                      JOD
                    </div>
                  </div>
                )}
                {contextApplication.data.scoring_log.guarantor_additional_income !==
                  null && (
                  <div className={styles.item}>
                    <div className={styles.key}>Guarantor additional income</div>
                    <div className={styles.value}>
                      {contextApplication.data.scoring_log.guarantor_additional_income}{" "}
                      JOD
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Manual stop factors</div>
              <div className={styles.table}>
                <div className={styles.row}>
                  <div className={styles.col}>Group loan</div>
                  <div className={styles.col}>
                    {contextApplication.data.scoring_log.group_loan ? "Yes" : "No"}
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.group_loan === "approved" ||
                        contextApplication.manual_factors?.group_loan === null) &&
                        styles.green,
                      contextApplication.manual_factors?.group_loan === "rejected" &&
                        styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.group_loan
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>Prohibited loan purpose</div>
                  <div className={styles.col}>
                    {contextApplication.data.scoring_log.loan_purpose ? "Yes" : "No"}
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.loan_purpose === "approved" ||
                        contextApplication.manual_factors?.loan_purpose === null) &&
                        styles.green,
                      contextApplication.manual_factors?.loan_purpose === "rejected" &&
                        styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.loan_purpose
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>Age</div>
                  <div className={styles.col}>
                    {contextApplication.data.scoring_log.age} y/o
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.age === "approved" ||
                        contextApplication.manual_factors?.age === null) &&
                        styles.green,
                      contextApplication.manual_factors?.age === "rejected" && styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(contextApplication.manual_factors?.age)}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>Dual loans</div>
                  <div className={styles.col}>
                    {contextApplication.data.scoring_log.dual_loans ? "Yes" : "No"}
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.dual_loans === "approved" ||
                        contextApplication.manual_factors?.dual_loans === null) &&
                        styles.green,
                      contextApplication.manual_factors?.dual_loans === "rejected" &&
                        styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.dual_loans
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>Max days late payments</div>
                  <div className={styles.col}>
                    {contextApplication.data.scoring_log.max_days_late_payments}
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.max_days_late_payments ===
                        "approved" ||
                        contextApplication.manual_factors?.max_days_late_payments ===
                          null) &&
                        styles.green,
                      contextApplication.manual_factors?.max_days_late_payments ===
                        "rejected" && styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.max_days_late_payments
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    Client is already a guarantor for another active loan?
                  </div>
                  <div className={styles.col}>
                    {contextApplication.data.scoring_log.client_status_gurantor
                      ? "Yes"
                      : "No"}
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.client_status_gurantor ===
                        "approved" ||
                        contextApplication.manual_factors?.client_status_gurantor ===
                          null) &&
                        styles.green,
                      contextApplication.manual_factors?.client_status_gurantor ===
                        "rejected" && styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.client_status_gurantor
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    Family members loans (in the same house)
                  </div>
                  <div className={styles.col}>
                    {contextApplication.data.scoring_log.family_members_loans
                      ? "Yes"
                      : "No"}
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.family_members_loans ===
                        "approved" ||
                        contextApplication.manual_factors?.family_members_loans ===
                          null) &&
                        styles.green,
                      contextApplication.manual_factors?.family_members_loans ===
                        "rejected" && styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.family_members_loans
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    Family members delinquent (in the same house)
                  </div>
                  <div className={styles.col}>
                    {contextApplication.data.scoring_log.family_members_delinquent
                      ? "Yes"
                      : "No"}
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.family_members_delinquent ===
                        "approved" ||
                        contextApplication.manual_factors?.family_members_delinquent ===
                          null) &&
                        styles.green,
                      contextApplication.manual_factors?.family_members_delinquent ===
                        "rejected" && styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.family_members_delinquent
                    )}
                  </div>
                </div>
                {contextApplication.data.scoring_log.guarantor_age !== null && (
                  <div className={styles.row}>
                    <div className={styles.col}>Guarantor age</div>
                    <div className={styles.col}>
                      {contextApplication.data.scoring_log.guarantor_age}
                    </div>
                    <div
                      className={classNames(
                        styles.col,
                        (contextApplication.manual_factors?.guarantor_age ===
                          "approved" ||
                          contextApplication.manual_factors?.guarantor_age === null) &&
                          styles.green,
                        contextApplication.manual_factors?.guarantor_age === "rejected" &&
                          styles.red
                      )}
                    >
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.guarantor_age
                      )}
                    </div>
                  </div>
                )}
                {contextApplication.data.scoring_log.guarantor_has_active_loan !==
                  null && (
                  <div className={styles.row}>
                    <div className={styles.col}>Guarantor has active AMC loan?</div>
                    <div className={styles.col}>
                      {contextApplication.data.scoring_log.guarantor_has_active_loan
                        ? "Yes"
                        : "No"}
                    </div>
                    <div
                      className={classNames(
                        styles.col,
                        (contextApplication.manual_factors?.guarantor_has_active_loan ===
                          "approved" ||
                          contextApplication.manual_factors?.guarantor_has_active_loan ===
                            null) &&
                          styles.green,
                        contextApplication.manual_factors?.guarantor_has_active_loan ===
                          "rejected" && styles.red
                      )}
                    >
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.guarantor_has_active_loan
                      )}
                    </div>
                  </div>
                )}
                {contextApplication.data.scoring_log.guarantor_for_another_loan !==
                  null && (
                  <div className={styles.row}>
                    <div className={styles.col}>
                      Guarantor is already a guarantor for another active loan?
                    </div>
                    <div className={styles.col}>
                      {contextApplication.data.scoring_log.guarantor_for_another_loan
                        ? "Yes"
                        : "No"}
                    </div>
                    <div
                      className={classNames(
                        styles.col,
                        (contextApplication.manual_factors?.guarantor_for_another_loan ===
                          "approved" ||
                          contextApplication.manual_factors
                            ?.guarantor_for_another_loan === null) &&
                          styles.green,
                        contextApplication.manual_factors?.guarantor_for_another_loan ===
                          "rejected" && styles.red
                      )}
                    >
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.guarantor_for_another_loan
                      )}
                    </div>
                  </div>
                )}
                <div className={styles.row}>
                  <div className={styles.col}>Client DBR</div>
                  <div className={styles.col}>
                    {(contextApplication.data.scoring_log.client_dbr * 100).toFixed(1)} %
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.client_dbr === "approved" ||
                        contextApplication.manual_factors?.client_dbr === null) &&
                        styles.green,
                      contextApplication.manual_factors?.client_dbr === "rejected" &&
                        styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.client_dbr
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>Guarantor DBR</div>
                  <div className={styles.col}>
                    {(contextApplication.data.scoring_log.guarantor_dbr * 100).toFixed(1)}{" "}
                    %
                  </div>
                  <div
                    className={classNames(
                      styles.col,
                      (contextApplication.manual_factors?.guarantor_dbr === "approved" ||
                        contextApplication.manual_factors?.guarantor_dbr === null) &&
                        styles.green,
                      contextApplication.manual_factors?.guarantor_dbr === "rejected" &&
                        styles.red
                    )}
                  >
                    {stopFactorStatusTranscript(
                      contextApplication.manual_factors?.guarantor_dbr
                    )}
                  </div>
                </div>
                {contextApplication.data.scoring_log.legal_case !== null && (
                  <div className={styles.row}>
                    <div className={styles.col}>Risky legal case</div>
                    <div className={styles.col}>
                      {contextApplication.data.scoring_log.legal_case ? "Yes" : "No"}
                    </div>
                    <div
                      className={classNames(
                        styles.col,
                        (contextApplication.manual_factors?.legal_case === "approved" ||
                          contextApplication.manual_factors?.legal_case === null) &&
                          styles.green,
                        contextApplication.manual_factors?.legal_case === "rejected" &&
                          styles.red
                      )}
                    >
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.legal_case
                      )}
                    </div>
                  </div>
                )}
                {contextApplication.data.scoring_log.legal_case_date !== null && (
                  <div className={styles.row}>
                    <div className={styles.col}>Legal case date</div>
                    <div className={styles.col}>
                      {contextApplication.data.scoring_log.legal_case_date}
                    </div>
                    <div
                      className={classNames(
                        styles.col,
                        (contextApplication.manual_factors?.legal_case_date ===
                          "approved" ||
                          contextApplication.manual_factors?.legal_case_date === null) &&
                          styles.green,
                        contextApplication.manual_factors?.legal_case_date ===
                          "rejected" && styles.red
                      )}
                    >
                      {stopFactorStatusTranscript(
                        contextApplication.manual_factors?.legal_case_date
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.fieldGroup}>
              <div className={styles.fieldTitle}>Client data</div>
              <div className={styles.fieldList}>
                <Input
                  title="Branch"
                  id="branch"
                  value={branch.key}
                  hasFilterText
                  type="select"
                  options={[
                    {
                      key: "bayader",
                      value: "Bayader",
                    },
                    {
                      key: "zarqa",
                      value: "Zarqa",
                    },
                    {
                      key: "middle_east",
                      value: "Middle East",
                    },
                    {
                      key: "irbid",
                      value: "Irbid",
                    },
                    {
                      key: "swaileh",
                      value: "Swaileh",
                    },
                    {
                      key: "zarqa_jadeedeh",
                      value: "Zarqa Jadeedeh",
                    },
                    {
                      key: "rsaifeh",
                      value: "Rsaifeh",
                    },
                    {
                      key: "rsaifeh_(jabal_al_shamali)",
                      value: "Rsaifeh (Jabal Al Shamali)",
                    },
                    {
                      key: "hashmi",
                      value: "Hashmi",
                    },
                    {
                      key: "baqaa",
                      value: "Baqaa",
                    },
                    {
                      key: "marka",
                      value: "Marka",
                    },
                    {
                      key: "jabal_al_hussien",
                      value: "Jabal Al Hussien",
                    },
                    {
                      key: "aqaba",
                      value: "Aqaba",
                    },
                    {
                      key: "irbid-aydoun",
                      value: "Irbid Aydoun",
                    },
                    {
                      key: "madaba",
                      value: "Madaba",
                    },
                    {
                      key: "al_karak",
                      value: "Al Karak",
                    },
                    {
                      key: "ajloun",
                      value: "Ajloun",
                    },
                    {
                      key: "marj_al_hamam",
                      value: "Marj Al Hamam",
                    },
                    {
                      key: "abu_nseir",
                      value: "Abu Nseir",
                    },
                    {
                      key: "hai_nazal",
                      value: "Hai Nazal",
                    },
                    {
                      key: "al_salt",
                      value: "Al Salt",
                    },
                    {
                      key: "al_hurria",
                      value: "Al Hurria",
                    },
                    {
                      key: "jabal_al_naser",
                      value: "Jabal Al Naser",
                    },
                    {
                      key: "tabarbour",
                      value: "Tabarbour",
                    },
                    {
                      key: "jarash",
                      value: "Jarash",
                    },
                    {
                      key: "mota_office",
                      value: "Mota Office",
                    },
                    {
                      key: "mafraq",
                      value: "Mafraq",
                    },
                    {
                      key: "dir_allah",
                      value: "Dir Allah",
                    },
                    {
                      key: "head_office",
                      value: "Head Office",
                    },
                  ].sort((a, b) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0))}
                  onSelect={(option) => setBranch(option)}
                />
                <Input
                  title="Group loan"
                  id="group_loan"
                  value={group_loan.key}
                  type="select"
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) => setGroup_loan(option)}
                />
                <Input
                  title="Prohibited loan purpose"
                  id="loan_purpose"
                  value={loan_purpose.key}
                  type="select"
                  infobox={
                    <>
                      • Facilities required for the purposes of manufacturing or selling
                      weapons or related services.
                      <br />
                      <br />• Facilities required for gambling clubs or any purposes or
                      activities related to them.
                      <br />
                      <br />• Facilities required for the manufacture of cigarettes,
                      tobacco products and alcoholic beverages.
                      <br />
                      <br />• Facilities required for the production of prohibited medical
                      drugs.
                      <br />
                      <br />• Facilities required to finance political offices.
                    </>
                  }
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) => setLoan_purpose(option)}
                />
                <Input
                  title="Age"
                  value={age}
                  // min={18}
                  // max={73}
                  step={1}
                  type="integer"
                  onInput={(value) => setAge(value)}
                />
                <Input
                  title="Gender"
                  id="gender"
                  value={gender.key}
                  type="select"
                  disabled={group_loan.key === "1"}
                  options={[
                    {
                      key: "female",
                      value: "Female",
                    },
                    {
                      key: "male",
                      value: "Male",
                    },
                  ]}
                  onSelect={(option) => setGender(option)}
                />
                <Input
                  title="Family status"
                  id="maritalStatus"
                  value={maritalStatus.key}
                  type="select"
                  options={[
                    {
                      key: "divorced",
                      value: "Divorced",
                    },
                    {
                      key: "married",
                      value: "Married",
                    },
                    {
                      key: "single",
                      value: "Single",
                    },
                    {
                      key: "widowed",
                      value: "Widowed",
                    },
                  ]}
                  onSelect={(option) => setMaritalStatus(option)}
                />
                <Input
                  title="Dependants"
                  value={dependents}
                  step={1}
                  min={0}
                  max={97}
                  type="integer"
                  onInput={(value) => setDependents(value)}
                />
                <Input
                  title="Nationality"
                  id="nationality"
                  value={nationality.key}
                  type="select"
                  hasFilterText
                  options={[
                    {
                      key: "Saudi Arabia",
                      value: "Saudi Arabia",
                    },
                    {
                      key: "Iran",
                      value: "Iran",
                    },
                    {
                      key: "Qatar",
                      value: "Qatar",
                    },
                    {
                      key: "Jordan",
                      value: "Jordan",
                    },
                    {
                      key: "Yemen",
                      value: "Yemen",
                    },
                    {
                      key: "Palestine",
                      value: "Palestine",
                    },
                    {
                      key: "Lybia",
                      value: "Lybia",
                    },
                    {
                      key: "Afghanistan",
                      value: "Afghanistan",
                    },
                    {
                      key: "UAE",
                      value: "UAE",
                    },
                    {
                      key: "Israel",
                      value: "Israel",
                    },
                    {
                      key: "Syria",
                      value: "Syria",
                    },
                    {
                      key: "Oman",
                      value: "Oman",
                    },
                    {
                      key: "Egypt",
                      value: "Egypt",
                    },
                    {
                      key: "Morocco",
                      value: "Morocco",
                    },
                    {
                      key: "Cyprus",
                      value: "Cyprus",
                    },
                    {
                      key: "Sudan",
                      value: "Sudan",
                    },
                    {
                      key: "Lebanon",
                      value: "Lebanon",
                    },
                    {
                      key: "Kuwait",
                      value: "Kuwait",
                    },
                    {
                      key: "Iraq",
                      value: "Iraq",
                    },
                    {
                      key: "Bahrain",
                      value: "Bahrain",
                    },
                    {
                      key: "Turkey",
                      value: "Turkey",
                    },
                    {
                      key: "Tunisia",
                      value: "Tunisia",
                    },
                    {
                      key: "Pakistan",
                      value: "Pakistan",
                    },
                    {
                      key: "Djibout",
                      value: "Djibout",
                    },
                  ]}
                  onSelect={(option) => setNationality(option)}
                />
                <Input
                  title="Address"
                  value={address}
                  type="string"
                  onInput={(value) => setAddress(value)}
                />
                <Input
                  title="Repeated client?"
                  id="repeated_client"
                  value={repeated_client.key}
                  type="select"
                  options={[
                    {
                      key: "repeated",
                      value: "Repeated",
                    },
                    {
                      key: "new",
                      value: "New",
                    },
                  ]}
                  onSelect={(option) => setRepeated_client(option)}
                />
                <Input
                  title="Prior loans"
                  value={priorLoans}
                  min={1}
                  max={18}
                  step={1}
                  type="integer"
                  onInput={(value) => setPriorLoans(value)}
                />
                <Input
                  title="Active loans"
                  value={active_loans}
                  step={1}
                  type="integer"
                  onInput={(value) => setActive_loans(value)}
                />
                <Input
                  title="Remaining payments"
                  value={remaining_payments}
                  step={1}
                  type="float"
                  disabled={repeated_client.key === "new"}
                  onInput={(value) => setRemaining_payments(value)}
                />
                <Input
                  title="Postpone payments"
                  value={postpone_payments}
                  step={1}
                  type="float"
                  disabled={repeated_client.key === "new"}
                  onInput={(value) => setPostpone_payments(value)}
                />
                <Input
                  title="Early settlement"
                  value={early_settlement}
                  step={1}
                  type="integer"
                  disabled={repeated_client.key === "new"}
                  onInput={(value) => setEarly_settlement(value)}
                />
                <Input
                  title="Dual loans"
                  id="dual_loans"
                  value={dual_loans.key}
                  type="select"
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) => setDual_loans(option)}
                />
                <Input
                  title="Max days late payments"
                  value={max_days_late_payments}
                  step={1}
                  type="integer"
                  onInput={(value) => setMax_days_late_payments(value)}
                />
                <Input
                  title="Client is already a guarantor for another active loan?"
                  id="client_status_gurantor"
                  value={client_status_gurantor.key}
                  type="select"
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) => setClient_status_gurantor(option)}
                />
                <Input
                  title="Family members loans (in the same house)"
                  id="family_members_loans"
                  value={family_members_loans.key}
                  type="select"
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) => setFamily_members_loans(option)}
                />
                <Input
                  title="Family members delinquent (in the same house)"
                  id="family_members_delinquent"
                  value={family_members_delinquent.key}
                  type="select"
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) => setFamily_members_delinquent(option)}
                />
                <Input
                  title="Project net monthly income"
                  value={project_net_monthly_income}
                  step={1}
                  type="float"
                  onInput={(value) => setProject_net_monthly_income(value)}
                />
                <Input
                  title="Additional income"
                  value={additional_income}
                  step={1}
                  type="float"
                  onInput={(value) => setAdditional_income(value)}
                />
                <Input
                  title="Monthly payment for active loans"
                  value={monthly_payment_for_active_loans}
                  step={1}
                  type="float"
                  onInput={(value) => setMonthly_payment_for_active_loans(value)}
                />
                <Input
                  title="Guarantor age"
                  value={guarantor_age}
                  // min={18}
                  // max={73}
                  step={1}
                  type="integer"
                  disabled={group_loan.key === "1"}
                  onInput={(value) => setGuarantor_age(value)}
                />
                <Input
                  title="Guarantor is already a guarantor for another active loan?"
                  id="guarantor_for_another_loan"
                  value={guarantor_for_another_loan.key}
                  disabled={group_loan.key === "1"}
                  type="select"
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) => setGuarantor_for_another_loan(option)}
                />
                <Input
                  title="Guarantor has active AMC loan?"
                  id="guarantor_has_active_loan"
                  value={guarantor_has_active_loan.key}
                  type="select"
                  disabled={group_loan.key === "1"}
                  options={[
                    {
                      key: "1",
                      value: "Yes",
                    },
                    {
                      key: "0",
                      value: "No",
                    },
                  ]}
                  onSelect={(option) => setGuarantor_has_active_loan(option)}
                />
                <Input
                  title="Guarantor income"
                  value={guarantor_income}
                  step={1}
                  type="float"
                  disabled={group_loan.key === "1"}
                  onInput={(value) => setGuarantor_income(value)}
                />
                <Input
                  title="Guarantor additional income"
                  value={guarantor_additional_income}
                  step={1}
                  type="float"
                  disabled={group_loan.key === "1"}
                  onInput={(value) => setGuarantor_additional_income(value)}
                />
                <Input
                  title="Guarantor total monthly payments"
                  value={guarantor_total_monthly_payments}
                  step={1}
                  type="float"
                  disabled={group_loan.key === "1"}
                  onInput={(value) => setGuarantor_total_monthly_payments(value)}
                />
              </div>
            </div>
            <div className={styles.fieldGroup}>
              <div className={styles.fieldTitle}>Alternative data</div>
              <div className={styles.fieldList}>
                <Input
                  title="Client residence"
                  id="city"
                  value={city.key}
                  type="select"
                  hasFilterText
                  options={[
                    {
                      key: "al_balqa_governorate",
                      value: "Al Balqa Governorate",
                    },
                    {
                      key: "capital_governorate",
                      value: "Capital Governorate",
                    },
                    {
                      key: "zarqa_governorate",
                      value: "Zarqa Governorate",
                    },
                    {
                      key: "irbid_governorate",
                      value: "Irbid Governorate",
                    },
                    {
                      key: "aqaba_governorate",
                      value: "Aqaba Governorate",
                    },
                    {
                      key: "ajloun_governorate",
                      value: "Ajloun Governorate",
                    },
                    {
                      key: "madaba_governorate",
                      value: "Madaba Governorate",
                    },
                    {
                      key: "karak_governorate",
                      value: "Karak Governorate",
                    },
                    {
                      key: "jerash_governorate",
                      value: "Jerash Governorate",
                    },
                    {
                      key: "mafraq_governorate",
                      value: "Mafraq Governorate",
                    },
                  ].sort((a, b) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0))}
                  onSelect={(option) => setCity(option)}
                />
                <Input
                  title="Residence type"
                  id="residenceType"
                  value={residenceType.key}
                  type="select"
                  options={[
                    {
                      key: "Rent",
                      value: "Rent",
                    },
                    {
                      key: "Ownership",
                      value: "Ownership",
                    },
                    {
                      key: "On loan",
                      value: "On loan",
                    },
                    {
                      key: "Other",
                      value: "Other",
                    },
                    {
                      key: "None",
                      value: "None",
                    },
                  ]}
                  onSelect={(option) => setResidenceType(option)}
                />
                <Input
                  title="Transport"
                  id="transport"
                  value={transport.key}
                  type="select"
                  options={[
                    {
                      key: "Rent",
                      value: "Rent",
                    },
                    {
                      key: "Ownership",
                      value: "Ownership",
                    },
                    {
                      key: "On loan",
                      value: "On loan",
                    },
                    {
                      key: "Other",
                      value: "Other",
                    },
                    {
                      key: "None",
                      value: "None",
                    },
                  ]}
                  onSelect={(option) => setTransport(option)}
                />
                <Input
                  title="Transport manufacturing year"
                  value={transportManufacturingYear}
                  step={1}
                  min={1900}
                  max={new Date().getFullYear() + 2}
                  type="integer"
                  onInput={(value) => setTransportManufacturingYear(value)}
                />
                <Input
                  title="Employment status"
                  id="employmentStatus"
                  value={employmentStatus.key}
                  type="select"
                  options={[
                    {
                      key: "Employed",
                      value: "Employed",
                    },
                    {
                      key: "Unemployed",
                      value: "Unemployed",
                    },
                    {
                      key: "Other",
                      value: "Other",
                    },
                    {
                      key: "None",
                      value: "None",
                    },
                  ]}
                  onSelect={(option) => setEmploymentStatus(option)}
                />
                <Input
                  title="Employment level"
                  id="employmentLevel"
                  value={employmentLevel.key}
                  type="select"
                  disabled={employmentStatus.key === "None"}
                  options={[
                    {
                      key: "Government agency",
                      value: "Government agency",
                    },
                    {
                      key: "Public organization",
                      value: "Public organization",
                    },
                    {
                      key: "Private company",
                      value: "Private company",
                    },
                    {
                      key: "Individual entrepreneur",
                      value: "Individual entrepreneur",
                    },
                    {
                      key: "Other",
                      value: "Other",
                    },
                    {
                      key: "None",
                      value: "None",
                    },
                  ]}
                  onSelect={(option) => setEmploymentLevel(option)}
                />
                <Input
                  title="Work experience"
                  value={workExperience}
                  step={1}
                  min={0}
                  max={97}
                  type="integer"
                  disabled={employmentStatus.key === "None"}
                  onInput={(value) => setWorkExperience(value)}
                  unit="mon."
                />
                <Input
                  title="Phone OS"
                  id="phoneOS"
                  value={phoneOS.key}
                  type="select"
                  options={[
                    {
                      key: "iOS",
                      value: "IOS",
                    },
                    {
                      key: "Android",
                      value: "Android",
                    },
                    {
                      key: "Other",
                      value: "Other",
                    },
                    {
                      key: "None",
                      value: "None",
                    },
                  ]}
                  onSelect={(option) => setPhoneOS(option)}
                />
              </div>
            </div>
            <div className={styles.footerBox}>
              <div className={styles.buttonGroup}>
                {formValid && (
                  <Button pending={pending} onClick={handleCalculate}>
                    Calculate
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.result}>
        <ResultBox />
      </div>
    </form>
  );
};

export default Scoring;
