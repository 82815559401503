import React from "react";
import Icon from "icons";
import classNames from "classnames";
import { useDispatch, useSelector } from "store/hooks";
import Input from "components/input";
import Button from "components/button";
import request from "request";
import styles from "./result-box.module.scss";
import prettierStatus from "utils/prettier-status";

const ResultBox = () => {
  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);
  const [decision, setDecision] = React.useState({
    key: "",
    value: "",
  });
  const [commentary, setCommentary] = React.useState("");
  const [pending, setPending] = React.useState(false);

  const setFinalDecision = () => {
    setPending(true);
    request
      .put(`/applications/${contextApplication.data?.id}/review`, {
        decision: decision.key,
        commentary: commentary,
      })
      .then(() =>
        dispatch.application.FETCH_APPLICATION_BY_ID(contextApplication.data?.id)
      )
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.resultList}>
      {contextApplication.data?.expert_decision !== null && (
        <div className={classNames(styles.resultBox, styles.finalDecision)}>
          <div className={styles.resultBox_title}>Final decision</div>
          <div className={styles.finalResult}>
            <Icon
              size={120}
              name={
                contextApplication.data?.expert_decision === "approved"
                  ? "tickCircle"
                  : "closeFilled"
              }
              className={classNames(
                contextApplication.data?.expert_decision === "approved"
                  ? styles.green
                  : styles.red
              )}
            />
            <div
              className={classNames(
                styles.finalResultText,
                contextApplication.data?.expert_decision === "approved"
                  ? styles.green
                  : styles.red
              )}
            >
              {prettierStatus(contextApplication.data?.expert_decision)}
            </div>
            <div className={styles.finalResultDescription}>
              {contextApplication.data?.expert_decision_description}
            </div>
          </div>
        </div>
      )}
      <div className={styles.resultBox}>
        <div className={styles.resultBox_title}>Application status</div>
        {contextApplication.data?.is_approved !== null && (
          <div className={styles.finalResult}>
            <Icon
              size={120}
              name={
                contextApplication.data?.is_approved === "approved"
                  ? "tickCircle"
                  : "closeFilled"
              }
              className={classNames(
                contextApplication.data?.is_approved === "approved"
                  ? styles.green
                  : contextApplication.data?.is_approved === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            />
            <div
              className={classNames(
                styles.finalResultText,
                contextApplication.data?.is_approved === "approved"
                  ? styles.green
                  : contextApplication.data?.is_approved === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {prettierStatus(contextApplication.data?.is_approved)}
            </div>
          </div>
        )}
        <div className={styles.resultBox_resultText}>
          <div className={styles.item}>
            <div className={styles.key}>Legal case:</div>
            <div
              className={classNames(
                styles.value,
                !contextApplication.data?.legal_case_log &&
                  contextApplication.data?.ocr_log
                  ? styles.green
                  : !contextApplication.data?.legal_case_log
                  ? styles.grey
                  : contextApplication.data?.legal_case_log.is_approved === "review"
                  ? styles.yellow
                  : styles.red
              )}
            >
              {!contextApplication.data?.legal_case_log &&
              contextApplication.data?.ocr_log
                ? "Approved"
                : prettierStatus(contextApplication.data?.legal_case_log?.is_approved) ??
                  "No decision"}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>OCR:</div>
            <div
              className={classNames(
                styles.value,
                !contextApplication.data?.ocr_log?.is_approved
                  ? styles.grey
                  : contextApplication.data?.ocr_log?.is_approved === "approved"
                  ? styles.green
                  : contextApplication.data?.ocr_log?.is_approved === "review"
                  ? styles.yellow
                  : styles.red
              )}
            >
              {prettierStatus(contextApplication.data?.ocr_log?.is_approved) ??
                "No decision"}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Scoring:</div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.scoring_log?.is_approved === "rejected"
                  ? styles.red
                  : contextApplication.data?.scoring_log?.is_approved === "approved"
                  ? styles.green
                  : contextApplication.data?.scoring_log?.is_approved === "review"
                  ? styles.yellow
                  : styles.grey
              )}
            >
              {contextApplication.data?.scoring_log?.is_approved
                ? prettierStatus(contextApplication.data?.scoring_log?.is_approved) +
                  (contextApplication.data?.scoring_log.prediction
                    ? ` (${contextApplication.data?.scoring_log.prediction}%)`
                    : "")
                : "No decision"}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Manual stop factors:</div>
            <div
              className={classNames(
                styles.value,
                !contextApplication.manual_factors?.is_approved
                  ? styles.grey
                  : contextApplication.manual_factors?.is_approved === "approved"
                  ? styles.green
                  : contextApplication.manual_factors?.is_approved === "rejected"
                  ? styles.red
                  : styles.yellow
              )}
            >
              {prettierStatus(contextApplication.manual_factors?.is_approved) ??
                "No decision"}
            </div>
          </div>
        </div>
      </div>
      {contextApplication.data?.is_approved === "review" &&
        contextApplication.data?.expert_decision === null && (
          <div className={classNames(styles.resultBox, styles.finalDecision)}>
            <div className={styles.resultBox_title}>Final decision</div>
            <div className={styles.resultBox_resultText}>
              <Input
                value={decision.key}
                id="decision"
                title="Decision"
                placeholder="Select final decision"
                type="select"
                onSelect={(option) => setDecision(option)}
                options={[
                  {
                    key: "approved",
                    value: "Approve",
                  },
                  {
                    key: "rejected",
                    value: "Reject",
                  },
                ]}
              />
              <Input
                id="commentary"
                title="Comment"
                value={commentary}
                type="textarea"
                onInput={(value) => setCommentary(value)}
              />
              {decision.key !== "" && commentary !== "" && (
                <Button color="violet" pending={pending} onClick={setFinalDecision}>
                  Submit
                </Button>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default ResultBox;
