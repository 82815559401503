import React from "react";
import { Document, Page, PDFViewer, Text, View } from "@react-pdf/renderer";

import { pdfStyles } from "pages/ocr";
import { useClientContext } from "..";
import ResultBox from "../components/result-box";
import mainTranscript from "utils/transcript";
import transcript from "../utils/transcript";

const Result = () => {
  const { group, stop_factor_list } = useClientContext();
  if (
    !group ||
    !group.scoring_approved ||
    !group.legal_case_approved ||
    !stop_factor_list
  )
    return (
      <>
        <div />
        <ResultBox />
      </>
    );
  return (
    <>
      <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
        <Document>
          <Page style={pdfStyles.page}>
            <View style={pdfStyles.heading}>
              <Text>
                Result:{" "}
                <Text
                  style={
                    group.is_approved === "approved"
                      ? pdfStyles.approved
                      : group.is_approved === "rejected"
                      ? pdfStyles.rejected
                      : pdfStyles.review
                  }
                >
                  {group.is_approved === "approved"
                    ? "Approved"
                    : group.is_approved === "rejected"
                    ? "Rejected"
                    : "Review"}
                </Text>
              </Text>
            </View>
            <View style={pdfStyles.heading3}>
              <Text>
                {group.prediction && `Prediction: ${group.prediction.toFixed(2)}%`}
              </Text>
            </View>
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.title}>Credit info</Text>
              <View style={pdfStyles.table}>
                {row2("Amount", `${group.applications[0].amount.toFixed(2)} JOD`)}
                {row2("Duration", `${group.applications[0].duration} mon.`)}
                {row2(
                  "Interest rate (annual)",
                  `${group.applications[0].interest_rate * 100} %`
                )}
                {row2("Number of clients", group.applications.length)}
                {row2(
                  "Branch",
                  mainTranscript.branch.getValueFromKey(
                    group.applications[0].scoring_log?.branch ?? ""
                  )
                )}
                {row2(
                  "Prohibited loan purpose",
                  group.applications[0].scoring_log?.loan_purpose ? "Yes" : "No"
                )}
                {row2("Active loans", group.applications[0].scoring_log?.active_loans)}
                {row2(
                  "Remaining payments",
                  group.applications[0].scoring_log?.remaining_payments
                )}
                {row2(
                  "Client residence",
                  mainTranscript.city.getValueFromKey(
                    group.applications[0].scoring_log?.city ?? ""
                  )
                )}
                {row2(
                  "Threshold",
                  `${Number(group.applications[0].scoring_log?.threshold) * 100} %`
                )}
              </View>
            </View>
          </Page>
          {group.applications.map((app, index) => {
            const stop_factor = stop_factor_list.find((s) => s.id === app.id);
            return (
              <React.Fragment key={app.id}>
                <Page style={pdfStyles.page}>
                  <View style={pdfStyles.heading}>
                    <Text>
                      Client {index + 1}:{" "}
                      <Text
                        style={
                          app.scoring_log?.is_approved === "approved"
                            ? pdfStyles.approved
                            : app.scoring_log?.is_approved === "rejected"
                            ? pdfStyles.rejected
                            : pdfStyles.review
                        }
                      >
                        {app.scoring_log?.is_approved &&
                          (app.scoring_log?.is_approved === "approved"
                            ? "Approved"
                            : app.scoring_log?.is_approved === "rejected"
                            ? "Rejected"
                            : "Review")}{" "}
                      </Text>
                    </Text>
                  </View>
                  <View style={pdfStyles.heading3}>
                    <Text>
                      {app.scoring_log?.prediction &&
                        `Prediction: ${app.scoring_log?.prediction}%`}
                    </Text>
                  </View>
                  <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>Client data</Text>
                    <View style={pdfStyles.table}>
                      {row2("Gender", transcript.gender(app.scoring_log?.gender ?? ""))}
                      {row2(
                        "Family status",
                        transcript.family_status(app.scoring_log?.family_status ?? "")
                      )}
                      {row2("Dependants", app.scoring_log?.dependants)}
                      {row2("Prior loans", app.scoring_log?.prior_loans)}
                    </View>
                  </View>
                  <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>Alternative data</Text>
                    <View style={pdfStyles.table}>
                      {row2("Phone OS", app.scoring_log?.phone_os)}
                      {row2("Transport", app.scoring_log?.transport)}
                      {row2("Residence Type", app.scoring_log?.residence_type)}
                      {row2("Employment Level", app.scoring_log?.employment_level)}
                      {row2("Employment Status", app.scoring_log?.employment_status)}
                      {row2("Work Experience", app.scoring_log?.work_experience)}
                      {row2(
                        "Transport Manufacturing Year",
                        app.scoring_log?.transport_manufacturing_year
                      )}
                      {row2("Nationality", app.scoring_log?.nationality)}
                      {row2("Address", app.scoring_log?.address)}
                      {row2("Repeated client?", app.scoring_log?.repeated_client)}
                      {row2("Remaining payments", app.scoring_log?.remaining_payments)}
                      {row2("Postpone payments", app.scoring_log?.postpone_payments)}
                      {row2("Early settlement", app.scoring_log?.early_settlement)}
                      {row2(
                        "Project net monthly income",
                        `${app.scoring_log?.project_net_monthly_income.toFixed(2)} JOD`
                      )}
                      {row2(
                        "Additional income",
                        `${app.scoring_log?.additional_income.toFixed(2)} JOD`
                      )}
                      {row2(
                        "Monthly payment for active loans",
                        `${app.scoring_log?.monthly_payment_for_active_loans.toFixed(
                          2
                        )} JOD`
                      )}
                      {row2(
                        "Monthly payment",
                        `${app.scoring_log?.monthly_payment?.toFixed(2)} JOD`
                      )}
                    </View>
                  </View>
                </Page>
                <Page style={pdfStyles.page}>
                  <View style={pdfStyles.heading3}>
                    <Text>Client {index + 1}</Text>
                  </View>
                  <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>Manual stop factors</Text>
                    <View style={pdfStyles.table}>
                      {row3(
                        "New financial legal case",
                        app.legal_case_log?.financial_legal_case === "approved"
                          ? "N/A"
                          : app.legal_case_log?.financial_legal_case === "rejected"
                          ? "Yes"
                          : "No",
                        app.legal_case_log?.financial_legal_case === "approved"
                          ? "Pass"
                          : app.legal_case_log?.financial_legal_case === "rejected"
                          ? "Fail"
                          : "Review"
                      )}
                      {row3(
                        "Bad credit reputation case",
                        app.legal_case_log?.bad_credit_reputation_case === "approved"
                          ? "N/A"
                          : app.legal_case_log?.bad_credit_reputation_case === "rejected"
                          ? "Yes"
                          : "No",
                        app.legal_case_log?.bad_credit_reputation_case === "approved"
                          ? "Pass"
                          : app.legal_case_log?.bad_credit_reputation_case === "rejected"
                          ? "Fail"
                          : "Review"
                      )}
                      {row3(
                        "Legal case date",
                        app.legal_case_log?.legal_case_date ?? "N/A",
                        ""
                      )}
                      {row3(
                        "Age",
                        app.scoring_log?.age + " y/o",
                        stop_factor?.age === "approved" ? "Pass" : "Rejected"
                      )}
                      {row3(
                        "Dual loans",
                        app.scoring_log?.dual_loans ? "Yes" : "No",
                        stop_factor?.dual_loans === "approved" ? "Pass" : "Rejected"
                      )}
                      {row3(
                        "Max days late payments",
                        app.scoring_log?.max_days_late_payments,
                        stop_factor?.max_days_late_payments === "approved"
                          ? "Pass"
                          : "Rejected"
                      )}
                      {row3(
                        "Client is already a guarantor for another active loan?",
                        app.scoring_log?.client_status_gurantor ? "Yes" : "No",
                        stop_factor?.client_status_gurantor === "approved"
                          ? "Pass"
                          : "Rejected"
                      )}
                      {row3(
                        "Family members loans (in the same house)",
                        app.scoring_log?.family_members_loans ? "Yes" : "No",
                        stop_factor?.family_members_loans === "approved"
                          ? "Pass"
                          : "Rejected"
                      )}
                      {row3(
                        "Family members delinquent (in the same house)",
                        app.scoring_log?.family_members_delinquent ? "Yes" : "No",
                        stop_factor?.family_members_delinquent === "approved"
                          ? "Pass"
                          : "Rejected"
                      )}
                      {row3(
                        "Client DBR",
                        (Number(app.scoring_log?.client_dbr) * 100).toFixed(1) + "%",
                        stop_factor?.client_dbr === "approved" ? "Pass" : "Rejected"
                      )}
                    </View>
                  </View>
                </Page>
              </React.Fragment>
            );
          })}
        </Document>
      </PDFViewer>
      <ResultBox />
    </>
  );
};

const row2 = (key: any, value: any) => (
  <View style={pdfStyles.tr}>
    <View style={pdfStyles.td}>
      <Text>{key}</Text>
    </View>
    <View style={pdfStyles.td}>
      <Text>{value}</Text>
    </View>
  </View>
);

const row3 = (col1: any, col2: any, col3: any) => (
  <View style={pdfStyles.tr}>
    <View style={pdfStyles.td}>
      <Text>{col1}</Text>
    </View>
    <View style={pdfStyles.td}>
      <Text>{col2}</Text>
    </View>
    <View style={pdfStyles.td}>
      <Text>{col3}</Text>
    </View>
  </View>
);

export default Result;
