import React from "react";
import prettierNumber from "utils/prettier-number";
import { useClientContext } from "..";
import styles from "../application-group.module.scss";
import ResultBox from "../components/result-box";
import mainTranscript from "utils/transcript";
import global from "global";
import request from "request";

const Main = () => {
  const { group } = useClientContext();
  const [macrodata, setMacrodata] = React.useState<global.macrodata>();

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);

  if (!macrodata) return <></>;
  return (
    <>
      <div className={styles.main}>
        <div className={styles.title}>Group common info</div>
        <div className={styles.item}>
          <div className={styles.key}>Amount</div>
          <div className={styles.value}>
            {prettierNumber(group.applications[0].amount)} JOD
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Duration</div>
          <div className={styles.value}>{group.applications[0].duration} mon.</div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Interest rate (annual)</div>
          <div className={styles.value}>
            {Number(group.applications[0].interest_rate * 100)} %
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Threshold</div>
          <div className={styles.value}>
            {(
              Number(
                group.applications[0].scoring_log?.threshold ?? macrodata?.threshold
              ) * 100
            ).toFixed()}{" "}
            %
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Branch</div>
          <div className={styles.value}>
            {mainTranscript.branch.getValueFromKey(
              group.applications[0].scoring_log?.branch ?? ""
            )}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Prohibited loan purpose</div>
          <div className={styles.value}>
            {group.applications[0].scoring_log?.loan_purpose ? "Yes" : "No"}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Active loans</div>
          <div className={styles.value}>
            {group.applications[0].scoring_log?.active_loans}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Remaining payments</div>
          <div className={styles.value}>
            {group.applications[0].scoring_log?.remaining_payments}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Client residence</div>
          <div className={styles.value}>
            {mainTranscript.city.getValueFromKey(
              group.applications[0].scoring_log?.city ?? ""
            )}
          </div>
        </div>
      </div>
      <ResultBox />
    </>
  );
};

export default Main;
