const transcript = {
  gender: (key: string) =>
    ({
      female: "Female",
      Female: "female",
      male: "Male",
      Male: "male",
    }[key]),
  family_status: (key: string) =>
    ({
      divorced: "Divorced",
      Divorced: "divorced",
      married: "Married",
      Married: "married",
      single: "Single",
      Single: "single",
      widowed: "Widowed",
      Widowed: "widowed",
    }[key]),
  repeated_client: (key: string) =>
    ({
      repeated: "Repeated",
      Repeated: "repeated",
      new: "New",
      New: "new",
    }[key]),
  dual_loans: (key: string) =>
    ({
      "1": "Yes",
      Yes: true,
      "0": "No",
      No: false,
    }[key]),
  client_status_gurantor: (key: string) =>
    ({
      "1": "Yes",
      Yes: true,
      "0": "No",
      No: false,
    }[key]),
  family_members_loans: (key: string) =>
    ({
      "1": "Yes",
      Yes: true,
      "0": "No",
      No: false,
    }[key]),
  family_members_delinquent: (key: string) =>
    ({
      "1": "Yes",
      Yes: true,
      "0": "No",
      No: false,
    }[key]),
};

export default transcript;
