import React from "react";
import { useClientContext } from "..";
import { OCRPdf } from "pages/ocr";
import styles from "../application-group.module.scss";
import { PDFViewer } from "@react-pdf/renderer";

const OCR = () => {
  const { application, OCRFile, setOCRFile } = useClientContext();

  const handleFileChangeCRIF = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files)
      setOCRFile({
        ...OCRFile,
        file: event.target.files[0],
      });
  };

  if (application.ocr_log)
    return (
      <div className={styles.ocr_result}>
        <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
          <OCRPdf application={application} />
        </PDFViewer>
      </div>
    );

  return (
    <div className={styles.ocr}>
      <label className={styles.file_input}>
        <div className={styles.file_input_title}>CRIF:</div>
        {OCRFile.file?.name ?? (
          <div className={styles.file_input_description}>
            Upload <span className={styles.bold}>.pdf</span> document
          </div>
        )}
        <input
          accept="application/pdf"
          type="file"
          onChange={(e) => {
            handleFileChangeCRIF(e);
            (e.target as any).value = null;
          }}
        />
      </label>
    </div>
  );
};

export default OCR;
