import React from "react";
import classNames from "classnames";
import { NavLink, Outlet, useParams } from "react-router-dom";

import { useClientContext } from "..";
import styles from "../application-group.module.scss";
import ResultBox from "../components/result-box";
import ClientResultBox from "../components/client-result-box";

const Client = () => {
  const { id } = useParams();
  const context = useClientContext();

  if (!context.application) return <></>;
  return (
    <>
      <div className={styles.client}>
        <div className={styles.navigation}>
          <NavLink
            end
            to={`/application-group/${id}/client/${context.application.id}`}
            draggable={false}
            className={({ isActive }) =>
              classNames(styles.link, isActive && styles.active)
            }
          >
            Legal case
          </NavLink>
          <NavLink
            end
            to={`/application-group/${id}/client/${context.application.id}/ocr`}
            draggable={false}
            className={({ isActive }) =>
              classNames(styles.link, isActive && styles.active)
            }
          >
            OCR
          </NavLink>
          <NavLink
            to={`/application-group/${id}/client/${context.application.id}/scoring`}
            draggable={false}
            className={({ isActive }) =>
              classNames(styles.link, isActive && styles.active)
            }
          >
            Scoring
          </NavLink>
        </div>
        <div className={styles.content}>
          <Outlet context={context} />
        </div>
      </div>
      <div className={styles.resultboxgroup}>
        <ClientResultBox />
        <ResultBox />
      </div>
    </>
  );
};

export default Client;
