import { NavLink, Outlet } from "react-router-dom";

import Button from "components/button";
import styles from "./home.module.scss";

const Home = () => (
  <div className={styles.results} id="applicationsHistory">
    <div className={styles.container}>
      {/* <div style={{ display: "flex", gap: "10px" }}>
        <NavLink to="/">
          {({ isActive }) => (
            <Button color={isActive ? "blue" : "grey"}>Single loans</Button>
          )}
        </NavLink>
        <NavLink to="/group-loans">
          {({ isActive }) => (
            <Button color={isActive ? "blue" : "grey"}>Group loans</Button>
          )}
        </NavLink>
      </div> */}
      <Outlet />
    </div>
  </div>
);

export default Home;
