import isDev from "./is-dev";

const isTestDeploy = process.env.REACT_APP_TEST_DEPLOY === "1";

const devURL = "https://amc.eastasia.cloudapp.azure.com/demo";

const prodURL = "https://amc.eastasia.cloudapp.azure.com/api/";

const baseURL = isTestDeploy || isDev ? devURL : prodURL;

export default baseURL;
