import Button from "components/button";
import global from "global";
import React from "react";
import { useSelector } from "store/hooks";
import {
  Document,
  Font,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ResultBox from "components/result-box";
import Loading from "components/loading";
import { useOCR } from "layouts/application";
import Input from "components/input";
import ToggleBox from "components/toggle-box";
import scoringStyles from "../scoring/scoring.module.scss";
import styles from "./ocr.module.scss";
import classNames from "classnames";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "medium",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: "bold",
    },
  ],
});

export const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "10px",
    fontWeight: "medium",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "3px",
    marginTop: "10px",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  heading2: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  heading3: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
  },
  text: {
    fontSize: "14px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  th: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  result: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rejected: {
    color: "red",
  },
  approved: {
    color: "green",
  },
  review: {
    color: "orange",
  },
});

const OCR = () => {
  const contextApplication = useSelector((state) => state.application);

  const [fileCRIF, setFileCRIF] = React.useState<File | null>();
  const {
    new_financial_legal_case,
    set_new_financial_legal_case,
    bad_credit_reputation_case,
    set_bad_credit_reputation_case,
    hasLegalCase,
    setHasLegalCase,
    legalCaseDate,
    setLegalCaseDate,
    handleRunOCR,
    ocrPending,
  } = useOCR();

  const [formValid, setFormValid] = React.useState(false);

  const handleFileChangeCRIF = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) setFileCRIF(event.target.files[0]);
  };

  React.useEffect(() => {
    setFormValid(
      !!fileCRIF &&
        (!hasLegalCase ||
          (new_financial_legal_case.key !== "" &&
            bad_credit_reputation_case.key !== "" &&
            legalCaseDate.length === 10 &&
            hasLegalCase))
    );
  }, [
    bad_credit_reputation_case.key,
    contextApplication.data?.legal_case_log,
    fileCRIF,
    hasLegalCase,
    legalCaseDate,
    new_financial_legal_case.key,
  ]);

  return (
    <div className={styles.ocr}>
      <div className={scoringStyles.section}>
        {!ocrPending && contextApplication.data?.ocr_log && (
          <>
            <div className={scoringStyles.creditDataBox}>
              <div className={scoringStyles.title}>Legal case</div>
              <div className={scoringStyles.table}>
                <div className={scoringStyles.row}>
                  <div className={scoringStyles.col}>New financial legal case</div>
                  <div className={scoringStyles.col}>
                    {!contextApplication.data.legal_case_log
                      ? "N/A"
                      : contextApplication.data.legal_case_log?.financial_legal_case ===
                        "rejected"
                      ? "Yes"
                      : "No"}
                  </div>
                  <div
                    className={classNames(
                      scoringStyles.col,
                      !contextApplication.data.legal_case_log
                        ? scoringStyles.green
                        : contextApplication.data.legal_case_log?.financial_legal_case ===
                          "rejected"
                        ? scoringStyles.red
                        : scoringStyles.yellow
                    )}
                  >
                    {!contextApplication.data.legal_case_log
                      ? "Pass"
                      : contextApplication.data.legal_case_log?.financial_legal_case ===
                        "rejected"
                      ? "Fail"
                      : "Review"}
                  </div>
                </div>
                <div className={scoringStyles.row}>
                  <div className={scoringStyles.col}>Bad credit reputation case</div>
                  <div className={scoringStyles.col}>
                    {!contextApplication.data.legal_case_log
                      ? "N/A"
                      : contextApplication.data.legal_case_log
                          ?.bad_credit_reputation_case === "rejected"
                      ? "Yes"
                      : "No"}
                  </div>
                  <div
                    className={classNames(
                      scoringStyles.col,
                      !contextApplication.data.legal_case_log
                        ? scoringStyles.green
                        : contextApplication.data.legal_case_log
                            ?.bad_credit_reputation_case === "rejected"
                        ? scoringStyles.red
                        : scoringStyles.yellow
                    )}
                  >
                    {!contextApplication.data.legal_case_log
                      ? "Pass"
                      : contextApplication.data.legal_case_log
                          ?.bad_credit_reputation_case === "rejected"
                      ? "Fail"
                      : "Review"}
                  </div>
                </div>
                <div className={scoringStyles.row}>
                  <div className={scoringStyles.col}>Legal case date</div>
                  <div className={scoringStyles.col}>
                    {contextApplication.data.legal_case_log?.legal_case_date ?? "N/A"}
                  </div>
                  <div className={scoringStyles.col} />
                </div>
              </div>
            </div>
            <div className={styles.pdf}>
              <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
                <OCRPdf application={contextApplication.data} />
              </PDFViewer>
            </div>
          </>
        )}
        {!contextApplication.data?.ocr_log && !ocrPending && (
          <>
            <div className={styles.inputBox}>
              {!contextApplication.data?.legal_case_log && (
                <div className={scoringStyles.row}>
                  <label className={scoringStyles.toggleBoxInput}>
                    <ToggleBox
                      active={hasLegalCase}
                      onToggle={() => setHasLegalCase(!hasLegalCase)}
                    />
                    Client has legal case
                  </label>
                </div>
              )}
              {hasLegalCase && (
                <div className={scoringStyles.fieldList}>
                  <Input
                    title="New financial legal case"
                    id="new_financial_legal_case"
                    value={new_financial_legal_case.key}
                    type="select"
                    options={[
                      {
                        key: "yes",
                        value: "Yes",
                      },
                      {
                        key: "no",
                        value: "No",
                      },
                    ]}
                    // infobox={
                    //   <>
                    //     New financial legal case or bad credit
                    //     <br />
                    //     reputation case
                    //   </>
                    // }
                    onSelect={(option) => set_new_financial_legal_case(option)}
                  />
                  <Input
                    title="Bad credit reputation case"
                    id="bad_credit_reputation_case"
                    value={bad_credit_reputation_case.key}
                    type="select"
                    options={[
                      {
                        key: "yes",
                        value: "Yes",
                      },
                      {
                        key: "no",
                        value: "No",
                      },
                    ]}
                    onSelect={(option) => set_bad_credit_reputation_case(option)}
                  />
                  <Input
                    title="Legal case date"
                    value={legalCaseDate}
                    type="string"
                    maxLength={10}
                    onInput={(vaL) => {
                      let value = vaL.replaceAll(/[^0-9]/g, "");

                      if (value.length > 2)
                        value = value.slice(0, 2) + "/" + value.slice(2);

                      if (value.length > 5)
                        value = value.slice(0, 5) + "/" + value.slice(5);

                      setLegalCaseDate(value);
                    }}
                    realPlaceholder="DD/MM/YYYY"
                  />
                </div>
              )}
              <label className={styles.fileInput}>
                <div className={styles.fileInputTitle}>CRIF:</div>
                {fileCRIF?.name ?? (
                  <div className={styles.fileInputDescription}>
                    Upload <span className={styles.bold}>.pdf</span> document
                  </div>
                )}
                <input
                  accept="application/pdf"
                  type="file"
                  onChange={handleFileChangeCRIF}
                />
              </label>
              {formValid && (
                <div className={styles.footerBox}>
                  <div className={styles.buttonGroup}>
                    <Button onClick={() => handleRunOCR(fileCRIF!)}>Run OCR</Button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {ocrPending && (
          <div
            style={{
              width: "100%",
              background: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        )}
      </div>
      <div className={styles.result}>
        <div className={styles.resultBox}>
          <ResultBox />
        </div>
      </div>
    </div>
  );
};

export const OCRPdf = ({ application }: { application: global.application }) => {
  return (
    <Document>
      {/* OCR */}
      {application?.ocr_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>OCR report</Text>
            <Text style={pdfStyles.heading2}>
              Decision:{" "}
              <Text
                style={
                  application.ocr_log.is_approved === "approved"
                    ? pdfStyles.approved
                    : application.ocr_log.is_approved === "review"
                    ? pdfStyles.review
                    : pdfStyles.rejected
                }
              >
                {application.ocr_log.is_approved}
              </Text>
            </Text>
          </View>
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.text}>Application: {application?.id}</Text>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.th}>
                  <Text>CRIF parameter</Text>
                </View>
                <View style={pdfStyles.th}>
                  <Text>Value</Text>
                </View>
                <View style={pdfStyles.th}>
                  <Text>Check</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Credit score</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.credit_score?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.credit_score?.check === true && "pass"}
                    {application?.ocr_log.credit_score?.check === false && "review"}
                    {application?.ocr_log.credit_score?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Number of loans in client’s history</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.number_of_loans?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.number_of_loans?.check === true && "pass"}
                    {application?.ocr_log.number_of_loans?.check === false && "review"}
                    {application?.ocr_log.number_of_loans?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Payments with 30 - 89 delay days</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.delays30_89?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.delays30_89?.check === true && "pass"}
                    {application?.ocr_log.delays30_89?.check === false && "review"}
                    {application?.ocr_log.delays30_89?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Payments with more than 89 delay days</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.delays_above89?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.delays_above89?.check === true && "pass"}
                    {application?.ocr_log.delays_above89?.check === false && "review"}
                    {application?.ocr_log.delays_above89?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Rejected loans</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.rejected_loans?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.rejected_loans?.check === true && "pass"}
                    {application?.ocr_log.rejected_loans?.check === false && "review"}
                    {application?.ocr_log.rejected_loans?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Requested loans</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.requested_loans?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.requested_loans?.check === true && "pass"}
                    {application?.ocr_log.requested_loans?.check === false && "review"}
                    {application?.ocr_log.requested_loans?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>[Non-AMC] Total number of delayed payments</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.delay_payments?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.delay_payments?.check === true && "pass"}
                    {application?.ocr_log.delay_payments?.check === false && "review"}
                    {application?.ocr_log.delay_payments?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>[Non-AMC] Number of loans received in the last 2 months</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.new_loans?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.new_loans?.check === true && "pass"}
                    {application?.ocr_log.new_loans?.check === false && "review"}
                    {application?.ocr_log.new_loans?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>[Non-AMC] Number of active loans</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.number_of_active_loans?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.number_of_active_loans?.check === true &&
                      "pass"}
                    {application?.ocr_log.number_of_active_loans?.check === false &&
                      "review"}
                    {application?.ocr_log.number_of_active_loans?.check === null &&
                      "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>[Non-AMC] Total monthly payment in active loans</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.total_payments?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.total_payments?.check === true && "pass"}
                    {application?.ocr_log.total_payments?.check === false && "review"}
                    {application?.ocr_log.total_payments?.check === null && "reject"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>[Non-AMC] Active one-month loans</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{application?.ocr_log.one_month_loans?.value}</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {application?.ocr_log.one_month_loans?.check === true && "pass"}
                    {application?.ocr_log.one_month_loans?.check === false && "review"}
                    {application?.ocr_log.one_month_loans?.check === null && "reject"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default OCR;
